import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigationService } from '../../Common/NavigationServices';
import { getToolsSubHeaders } from './getToolsSubHeaders';

const ToolsSubHeader = () => {
    const getSelectedMenu = navigationService.getSelectedMenu();
    const { t } = useTranslation();
    const menus = getToolsSubHeaders();
    const captions = {
        manageBlockedEmails: t('tools_subheader__manage-blocked-emails'),
        vaioProviders: t('partner__provider-settings__title'),
        customerNotifications: t('admin__customer-notification__list__title'),
        dxsFeeds: t('common__dxs-feeds'),
        firmwareManagement: t('common__firmware-management'),
        tokenManagement: t('admin__token-management__tab-title')
    };
    const active = (menu) => getSelectedMenu === menu ? 'active_tab_sub' : '';
    return (menus.includes(getSelectedMenu) &&
        <div>
            <div className="subMenu menuBar ">
                <div className="subHeaderSection">
                    <ul className="subHeaders">
                        {menus.map((menu) => (
                            <li key={menu}>
                                <a
                                    className={'headerMenu ' + active(menu)}
                                    href={navigationService.getSubMenuUrl(menu)}
                                >{captions[menu]}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ToolsSubHeader;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, useAvailableFilters, applyGridFilters } from 'components/Common/Grid';
import compareVersions from 'helpers/sortComparators/version';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import sortBySelection from 'helpers/sortBySelection';
import { Actions } from './Actions';
import { deviceTypesTexts } from 'constants/device';
import { DateLib } from '@hme-cloud/utility-common';
import {
    applyPagination,
    formatUpgradeStatus,
    applyRawFieldsSorting,
    getSortComparators,
    applySearch,
} from '../../helpers';
import { ScheduleDays } from '../../ScheduleDays';
import './UpgradesList.css';

const gridHeaders = [
    {
        text: 'device-upgrades__grid__header--name',
        property: 'ScheduledUpgradeName',
        flex: 150,
        sortable: true,
    },
    {
        text: 'common__created-by',
        property: 'CreatedBy',
        className: 'hme-grid-cell-show-all-content',
        flex: 170,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--device-type',
        property: 'deviceType',
        className: 'hme-grid-cell-show-all-content',
        flex: 95,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--upgrade-version',
        property: 'UpgradeToVersion',
        className: 'hme-grid-cell-show-all-content',
        flex: 80,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--number-of-devices-upgraded',
        property: 'devicesCounters',
        className: 'hme-grid-cell-show-all-content hme-grid-cell-device-counters',
        flex: 100,
    },
    {
        text: 'device-upgrades__grid__header--start-date',
        property: 'UpgradeStartDate',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
        sortDirections: [0, -1, 1],
    },
    {
        text: 'device-upgrades__grid__header--upgrade-days-of-week',
        property: 'daysOfWeekCircles',
        className: 'hme-grid-cell-show-all-content',
        flex: 200,
    },
    {
        text: 'device-upgrades__grid__header--upgrade-upgrade-run-time',
        property: 'runTime',
        flex: 100,
    },
    {
        text: 'device-upgrades__grid__header--completed-date-time',
        property: 'LastDeviceUpgradeCompletedDate',
        className: 'hme-grid-cell-show-all-content',
        flex: 130,
        sortable: true,
        sortDirections: [0, -1, 1],
        infoText: 'device-upgrades__grid__header--completed-date-time--local-time',
    },
    {
        text: 'common__status',
        property: 'status',
        className: 'hme-grid-cell-status',
        headerClassName: 'hme-grid-filter-align-right',
        flex: 95,
        sortable: true,
    },
    {
        text: 'common__actions',
        property: 'actions',
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'hme-grid-centered-cell hme-grid-cell-actions',
        flex: 160,
    },
];

const filterOptions = {
    CreatedBy: {
        allText: 'device-upgrades__grid-filter__all-created-by',
    },
    deviceType: {
        allText: 'device-upgrades__grid-filter__all-device-types',
    },
    UpgradeToVersion: {
        allText: 'device-upgrades__grid-filter__all-upgrade-versions',
        sortComparator: (a, b) => compareVersions(a.UpgradeToVersion, b.UpgradeToVersion)
    },
    devicesCounters: {
        allText: 'common__all',
        items: [{
                text: 'Batches With Failures',
                value: true,
            }, {
                text: 'Batches Without Failures',
                value: false,
            }
        ],
        valuePropName: 'withFailures',
    },
    status: {
        allText: 'device-upgrades__grid-filter__all-statuses',
    },
};

const searchProperties = ['ScheduledUpgradeName', 'CreatedBy', 'deviceType', 'UpgradeToVersion', 'status'];
const sortDirections = [1, -1];

export const UpgradesList = ({
    search,
    deviceUpgrades,
    filteredDeviceUpgrades,
    paginationData,
    isLoading,
    onFilterUpgrades,
    onCancelUpgrade,
    gridFilters,
    setGridFilters,
    availableFilters,
    setAvailableFilters
}) => {
    const [rows, setRows] = useState([]);
    const [isConfirmPopupShown, setIsConfirmPopupShown] = useState(false);
    const [cancelUpgradeID, setCancelUpgradeID] = useState(false);
    // const [availableFilters, setAvailableFilters] = useState([]);
    // const [gridFilters, setGridFilters] = useState({});
    const [sortSelection, setSortSelection] = useState({ UpgradeStartDate: -1 });

    const { t } = useTranslation();

    const onCancelSelected = useCallback((upgradeID) => {
        setCancelUpgradeID(upgradeID);
        setIsConfirmPopupShown(true);
    }, []);

    const upgradesToRows = useCallback(
        ({ deviceUpgrades, t }) =>
            deviceUpgrades.map((upgrade) => {
                const status = formatUpgradeStatus(upgrade);

                const upgradeStartDate = new DateLib(new Date(upgrade.UpgradeStartDate) || '');

                const runTimeStart = new DateLib(upgrade.StartTime).formatInUTC(DateLib.FORMAT_TYPES.SHORTENED_HOURS_MINUTES);
                const runTimeEnd = new DateLib(upgrade.EndTime).formatInUTC(DateLib.FORMAT_TYPES.SHORTENED_HOURS_MINUTES);

                return {
                    ...upgrade,
                    deviceType: t(deviceTypesTexts[upgrade.DeviceTypeSource]) || upgrade.DeviceTypeSource,
                    daysOfWeekCircles: <ScheduleDays upgradeScheduleDays={upgrade.DaysofWeek} />,
                    UpgradeStartDateRaw: upgrade.UpgradeStartDate || '',
                    UpgradeStartDate: upgradeStartDate.format(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT),
                    runTime: `${runTimeStart} - ${runTimeEnd}`,
                    LastDeviceUpgradeCompletedDateRaw: upgrade.LastDeviceUpgradeCompletedDate || '',
                    LastDeviceUpgradeCompletedDate: upgrade.LastDeviceUpgradeCompletedDate
                        ? new DateLib(new Date(upgrade.LastDeviceUpgradeCompletedDate)).format(DateLib.FORMAT_TYPES.DATE_AND_TIME)
                        : '',
                    devicesCounters: `${upgrade.DeviceUpgradedCount} of ${upgrade.DeviceCount}`,
                    status,
                    withFailures: upgrade.FailedCount > 0,
                    actions: (
                        <Actions
                            upgradeID={upgrade.DeviceScheduledUpgradeID}
                            status={status}
                            onCancelSelected={onCancelSelected}
                        />
                    ),
                    rowClassName: `hme-grid-row--${status.toLowerCase().replace(' ', '-')}`,
                };
            }),
        [deviceUpgrades, deviceTypesTexts, formatUpgradeStatus, onCancelSelected, t],
    );

    const onCancelUpgradeHide = useCallback(() => setIsConfirmPopupShown(false), []);

    const onCancelUpgradeConfirm = useCallback(() => {
        onCancelUpgrade(cancelUpgradeID);
        setIsConfirmPopupShown(false);
    }, [cancelUpgradeID, onCancelUpgrade]);

    // define all upgrade rows for generating filter options with full data
    const upgradeRows = useMemo(() => upgradesToRows({ deviceUpgrades, t }), [deviceUpgrades, upgradesToRows, t]);

    // define filtered upgrade rows for showing in grid
    const filteredUpgradeRows = useMemo(
        () => upgradesToRows({ deviceUpgrades: filteredDeviceUpgrades, t }),
        [filteredDeviceUpgrades, upgradesToRows, t],
    );

    useEffect(() => {
        const upgradeRowsFilteredBySearch = search.length >=3 
            ? applySearch(upgradeRows, search, searchProperties)
            : upgradeRows;

        const sortedUpgradeRows = sortBySelection({
            list: applyGridFilters(filterOptions, upgradeRowsFilteredBySearch, gridFilters),
            sortSelection: applyRawFieldsSorting(sortSelection),
            comparators: getSortComparators(sortSelection),
        });

        setRows(applyPagination(sortedUpgradeRows, paginationData));
        onFilterUpgrades(sortedUpgradeRows);
    }, [search, sortSelection, upgradeRows, gridFilters, onFilterUpgrades]);

    useEffect(() => {
        setRows(applyPagination(filteredUpgradeRows, paginationData));
    }, [paginationData]);

    useAvailableFilters(filterOptions, upgradeRows, setGridFilters, setAvailableFilters);

    return (
        <div className="device-upgrades-list-grid hme-components">
            <Grid
                headers={gridHeaders}
                rows={rows}
                isLoading={isLoading}
                rowKey="DeviceScheduledUpgradeID"
                noRecordsMessage={t('common__no-devices--found')}
                sortDirections={sortDirections}
                sortSelection={sortSelection}
                filters={gridFilters}
                availableFilters={availableFilters}
                onFiltersChange={setGridFilters}
                onSortChange={setSortSelection}
            />
            <ConfirmPopupComponent
                show={isConfirmPopupShown}
                message={t('device-upgrades__warning__cancel-scheduled-upgrade')}
                onHide={onCancelUpgradeHide}
                onConfirm={onCancelUpgradeConfirm}
                cancelVerb={t('common__no-go-back')}
                confirmationVerb={t('common__yes-cancel-now')}
            />
        </div>
    );
};

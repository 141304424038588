export const headers = [{
    text: 'stores__list-header__store-information',
    property: [{
        text: 'common__brand',
        property: 'brandText',
        sortable: true,
    }, {
        text: 'common__store__number',
        property: 'Store_Number',
        sortable: true,
    }, {
        text: 'common__store__name',
        property: 'Store_Name',
        sortable: true,
    }, {
        text: 'common__store__address',
        property: 'Store_AddressLine1',
        sortable: true,
    }, {
        text: 'stores__list-header__city-state',
        property: 'cityString',
        sortable: true,
    }, {
        text: 'common__leaderboard',
        property: 'Group_Name',
        sortable: true,
    }, {
        text: 'stores__list-header__report-group',
        property: 'GroupName',
        sortable: true,
    }, {
        text: 'stores__list-header__actions',
        property: 'Actions',
        className: 'hme-stores-status--public__grid__action',
        headerClassName: 'hme-stores-status--public__grid__action',
    }],
}, {
    text: 'stores__list-header__device-information',
    property: [{
        text: 'common__device__serial-number--text',
        property: 'SerialNumber',
        sortable: true,
        flex: 1.25
    }, {
        text: 'common__device__product-id',
        property: 'DeviceProductID',
        sortable: true,
        flex: 1.25,
    }, {
        text: 'common__device__version',
        property: 'Version',
        sortable: true,
        flex: 0.5
    }, {
        text: 'common__status',
        property: 'Status',
        flex: 1.5,
        className: 'hme-stores-status--public__grid__status-cell',
        headerClassName: 'hme-stores-status--public__grid__header__status-cell',
        sortable: true,
    }],
}];

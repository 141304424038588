/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label } from 'library/Label';
import { Section } from 'library/Section';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent } from 'components/Inputs';
import { ReactComponent as ClockIcon } from 'assets/icons/icon-clock.svg';
import { SingleSelect } from 'library/SingleSelect';
import { TextArea } from 'library/TextArea';
import { vaioSettings } from 'pages/StoreFeatures/VaioProviders/helpers';
import { maskVaioProviderKey } from '../helpers';
import { PortTip } from './PortTip/PortTip';

import './DetailsSection.scss';

const { botAudioSourceTypes, botChannelTypes } = vaioSettings;

const BOT_AUDIO_SOURCE_TYPES = [
    { text: 'vaio-provider_audio-source--unprocessed', value: botAudioSourceTypes.unprocessed },
    { text: 'vaio-provider_audio-source--semi-processed', value: botAudioSourceTypes.semiProcessed },
    { text: 'vaio-provider_audio-source--processed', value: botAudioSourceTypes.processed },
];

const BOT_CHANNEL_OPTIONS = [
    { text: 0, value: botChannelTypes['0'] },
    { text: 1, value: botChannelTypes['1'] },
    { text: 2, value: botChannelTypes['2'] },
];

// eslint-disable-next-line complexity
export const DetailsSection = ({
    formValues,
    onChange,
    formErrors,
    requiredInputs,
    isKeyHidden
}) => {
    const { t } = useTranslation();
    const {
        url,
        httpsPort,
        wssPort,
        vaioKey,
        botName,
        reconnectionDelay,
        botAudioSourceType,
        botChannels
    } = formValues;

    const [maskedKey, setMaskedKey] = useState(maskVaioProviderKey(vaioKey));

    const onVaioInputChange = (e) => {
        setMaskedKey(e.target.value);
        onChange(e);
    };

    return (
        <Section
            className="new-vaio-provider-details"
            number={2}
            col={9}
            showNumber={false}
            title="common__details"
        >
            <div className="new-vaio-provider-details-url-input">
                <InputComponent
                    label={t('new-vaio-provider__form--url')}
                    name="url"
                    value={url}
                    onChange={onChange}
                    isRequired={requiredInputs.url}
                    maxLength={250}
                    universalOnChange={true}
                />
                <PortTip />
                {formErrors.url && <ErrorLabel>{t(formErrors.url)}</ErrorLabel>}
            </div>
            
            <div className="port-section">
                <div className="vaio-provider-form-short-input vaio-provider-form-add-margin-top">
                    <InputComponent
                        label={t('new-vaio-provider__form--https-port')}
                        name="httpsPort"
                        value={httpsPort}
                        onChange={onChange}
                        maxLength={5}
                        universalOnChange={true}
                        isRequired={requiredInputs.httpsPort}
                        isDisabled={!requiredInputs.httpsPort}
                    />
                </div>
                <div className="vaio-provider-form-short-input vaio-provider-form-add-margin-top vaio-provider-form-short-input-second-element">
                    <InputComponent
                        label={t('new-vaio-provider__form--wss-port')}
                        name="wssPort"
                        value={wssPort}
                        onChange={onChange}
                        maxLength={5}
                        universalOnChange={true}
                        isRequired={requiredInputs.wssPort}
                        isDisabled={!requiredInputs.wssPort}
                    />
                </div>
                {formErrors.wssPort || formErrors.httpsPort ? (
                    <div className="vaio-provider-form-port-errors">
                        <div className="vaio-provider-form-port-error">
                            {formErrors.httpsPort && <ErrorLabel>{t(formErrors.httpsPort)}</ErrorLabel>}
                        </div>
                        <div className="vaio-provider-form-port-error vaio-provider-form-port-error-second-element">
                            {formErrors.wssPort && <ErrorLabel>{t(formErrors.wssPort)}</ErrorLabel>}
                        </div>
                    </div>
                ) : ''}
            </div>

            <div className="vaio-provider-form-short-input">
                <div className="hme-input-container">
                    <p className="hme-label hme-vaio-label">
                        {t('new-vaio-provider__form--reconnection-time-interval')}
                    </p>
                    <InputComponent
                        className="hme-input-label-inside-variant hme-input-clock-variant no-top-margin"
                        maxLength={3}
                        label={t('common__seconds')}
                        name="reconnectionDelay"
                        value={reconnectionDelay}
                        onChange={onChange}
                        universalOnChange={true}
                        isRequired={requiredInputs.reconnectionDelay}
                    >
                        <ClockIcon className="input-clock-icon" />
                    </InputComponent>
                </div>
            </div>

            <div className="vaio-provider-form-short-input vaio-provider-form-short-input-second-element">
                <div className="hme-input-container">
                    <SingleSelect
                        value={botAudioSourceType}
                        placeholder={`-${t('new-vaio-provider__form--token-expiration-placeholder')}-`}
                        onChange={(value) => {
                            onChange({ target: { name: 'botAudioSourceType', value: parseInt(value) } });
                        }}
                        isDisabled={false}
                        isRequired={requiredInputs.botAudioSourceType}
                        items={BOT_AUDIO_SOURCE_TYPES}
                        label={t('new-vaio-provider__form--voice-agent-audio-source')}
                        withFilter={false}
                    />
                </div>
            </div>
            {formErrors.reconnectionDelay && <ErrorLabel>{t(formErrors.reconnectionDelay)}</ErrorLabel>}

            <div className='bot-section'>
                <div className="vaio-provider-form-short-input">
                    <div className="hme-input-container">
                        <SingleSelect
                            value={botChannels}
                            onChange={(value) => {
                                onChange({ target: { name: 'botChannels', value: parseInt(value) } });
                            }}
                            isDisabled={false}
                            isRequired={true}
                            withFilter={false}
                            items={BOT_CHANNEL_OPTIONS}
                            label={t('vaio-provider--bot-channels')}
                        />
                    </div>
                </div>
                <div className="vaio-provider-form-short-input vaio-provider-form-short-input-second-element">
                    <InputComponent
                        label={t('new-vaio-provider__form--bot-name')}
                        name="botName"
                        value={botName}
                        onChange={onChange}
                        maxLength={25}
                        universalOnChange={true}
                        isRequired={requiredInputs.botName}
                    />
                </div>
                {formErrors.botName ? (
                    <div className="vaio-provider-form-port-errors">
                        <div className="vaio-provider-form-port-error"></div>
                        <div className="vaio-provider-form-port-error vaio-provider-form-port-error-second-element">
                            {formErrors.botName && <ErrorLabel>{t(formErrors.botName)}</ErrorLabel>}
                        </div>
                    </div>
                ) : ''}
            </div>
            {!isKeyHidden && (
                <>
                    <Label as="label" htmlFor="vaioKey" className="vaio-provider-form-label">{t('new-vaio-provider__form--key')}</Label>
                    <TextArea
                        value={maskedKey}
                        onChange={onVaioInputChange}
                        name="vaioKey"
                        hideResize={true}
                        maxLength={2500}
                        rows={14}
                        isRequired={requiredInputs.vaioKey}
                        id="vaioKey"
                    />
                </>
            )}
            {!isKeyHidden && formErrors.vaioKey && <ErrorLabel>{t(formErrors.vaioKey)}</ErrorLabel>}
        </Section>
    );
};

DetailsSection.propTypes = {
    formValues: PropTypes.object,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    requiredInputs: PropTypes.object.isRequired,
    isKeyHidden: PropTypes.bool
};

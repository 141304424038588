import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarOverlay } from 'library/SidebarOverlay';
import { VaioConfigurationHistory } from 'library/Providers';
import { DEFAULT_TOKEN_MANAGER } from 'pages/APIManagement/APIManagementForm/helpers';

import { Header } from './Header';
import { Footer } from './Footer';
import { DetailsSection } from './DetailsSection';
import { GeneralSection } from './GeneralSection';
import {
    formDataValidator,
    isExpired,
    getFormValidations,
    regexUrlHasPort
} from './helpers';
import { vaioSettings } from '../VaioProviders/helpers';

import { ReactComponent as ClockIcon } from 'assets/icons/icon-clock.svg';

import './VaioProviderForm.scss';

const hiddenRows = ['vaio-provider--test-provider', 'common__updated-by'];

export const VAIO_DEFAULTS = {
    name: '',
    email: '',
    botName: '',
    keyExpiration: '',
    tokenExpiration: '',
    botAudioSourceType: vaioSettings.botAudioSourceTypes.unprocessed,
    url: '',
    httpsPort: '',
    wssPort: '',
    vaioKey: '',
    locationType: '',
    audioMode: '',
    outboundAudio: '',
    reconnectionDelay: '180',
    isTest: false,
    botChannels: 0,
};

const REQUIRED_INPUTS = {
    name: true,
    email: true,
    botName: false,
    keyExpiration: true,
    tokenExpiration: true,
    url: true,
    httpsPort: true,
    wssPort: true,
    vaioKey: true,
    audioMode: true,
    outboundAudio: true,
    reconnectionDelay: true,
    locationType: true,
    botAudioSourceType: true
};

export const SettingsForm = ({
    settings: originalSettings = VAIO_DEFAULTS,
    linkedPartner = DEFAULT_TOKEN_MANAGER,
    providerChangeHistory = [],
    isViewChangeHistoryEnabled = false,
    title = 'new-vaio-provider_title',
    isPartnerMode,
    onCancel,
    onSubmit
}) => {
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState(originalSettings);
    const [formErrors, setFormErrors] = useState(() => {
        return {
            ...(originalSettings.keyExpiration && isExpired(originalSettings.keyExpiration) ?
                // set warning for existing provider with expired key
                { keyExpiration: t('vaio-provider-form__error-key-has-expired') } : {}
            )
        };
    });
    const [requiredInputs, setRequiredInputs] = useState(REQUIRED_INPUTS);

    const [isSidebarOverlayShown, setIsSidebarOverlayShown] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const isOnPremiseLocationType = formValues.locationType === vaioSettings.locationTypes.onPremise;

    const onChange = useCallback((event) => {
        const { name, value } = event.target;

        const urlHasPort = name === 'url' && regexUrlHasPort.test(value);

        setFormValues((prevFormValues) => {
            return {
                ...prevFormValues,
                // if url has port, set httpsPort and wssPort to empty strings
                httpsPort: urlHasPort ? VAIO_DEFAULTS.httpsPort : prevFormValues.httpsPort,
                wssPort: urlHasPort ? VAIO_DEFAULTS.wssPort : prevFormValues.wssPort,
                [name]: value
            };
        });
    }, []);

    const toggleSidebar = useCallback(() => setIsSidebarOverlayShown((prev) => !prev), []);

    const handleSubmit = async () => {
        const errors = formDataValidator(getFormValidations(formValues, requiredInputs), t);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setSaveEnabled(false);
            return;
        }

        // Go through values in formValues and trim beginning and ending whitespace
        const trimmedFormValues = {};
        for (const key of Object.keys(formValues)) {
            trimmedFormValues[key] = typeof formValues[key] === 'string' ? formValues[key].trim() : formValues[key];
        }

        await onSubmit?.(trimmedFormValues);
    };

    useEffect(() => {
        const urlHasPort = regexUrlHasPort.test(formValues.url);

        setRequiredInputs((prevRequiredInputs) => {
            return {
                ...prevRequiredInputs,
                botName: formValues.botChannels !== 0,
                url: !isOnPremiseLocationType,
                keyExpiration: !isOnPremiseLocationType,
                vaioKey: !isOnPremiseLocationType,
                httpsPort: !urlHasPort,
                wssPort: !urlHasPort
            };
        });
    }, [formValues.url, formValues.botChannels, isOnPremiseLocationType]);

    useEffect(() => {
        let enableSave = true;

        // check if object formValues has same values as object existingFormValues
        const fieldsToValidate = Object.keys(formValues).filter((key) =>
            !(['keyExpiration', 'vaioKey'].includes(key) && formValues.locationType === vaioSettings.locationTypes.onPremise)
        );
        const isSame = fieldsToValidate.every((key) => formValues[key] === originalSettings[key]);

        // check if required inputs are filled
        for (const input of Object.keys(requiredInputs)) {
            if (!formValues[input] && requiredInputs[input]) {
                enableSave = false;
                break;
            }
        }

        // check if updated formValue fixes error
        if (formValues.name) {
            const formErrorsCopy = { ...formErrors };

            for (const error in formErrorsCopy) {
                if (!getFormValidations(formValues, requiredInputs)[error]) {
                    delete formErrorsCopy[error];
                }
            }
            const errorLength = Object.keys(formErrorsCopy).length;
            if (errorLength > 0) enableSave = false;
            if (errorLength !== Object.keys(formErrors).length) setFormErrors(formErrorsCopy);
        }

        setSaveEnabled(isSame ? false : enableSave);
    }, [formValues, formErrors, requiredInputs]);

    return (
        <div className="new-vaio-provider">
            <div className="new-vaio-provider__container">
                <SidebarOverlay
                    title={'vaio-provider__configuration-history'}
                    icon={<ClockIcon />}
                    isShown={isSidebarOverlayShown}
                    toggleShown={toggleSidebar}
                    variants={['right']}
                >
                    <VaioConfigurationHistory histories={providerChangeHistory} hiddenRows={isPartnerMode ? hiddenRows : []} />
                </SidebarOverlay>
                <div className="new-vaio-provider__content">
                    <Header
                        title={title}
                        onHistoryConfigurationShow={toggleSidebar}
                        isConfigurationHistoryLinkShown={isViewChangeHistoryEnabled}
                        isConfigurationHistoryLinkDisabled={!providerChangeHistory.length}
                    />
                    <div className="new-vaio-provider__sections">
                        <GeneralSection
                            formValues={formValues}
                            onChange={onChange}
                            formErrors={formErrors}
                            requiredInputs={requiredInputs}
                            isKeyExpirationHidden={isOnPremiseLocationType}
                            linkedPartner={linkedPartner}
                            isPartnerMode={isPartnerMode}
                        />
                        <DetailsSection
                            formValues={formValues}
                            onChange={onChange}
                            formErrors={formErrors}
                            requiredInputs={requiredInputs}
                            isKeyHidden={isOnPremiseLocationType}
                        />
                    </div>
                </div>
            </div>
            <Footer isSubmitEnabled={saveEnabled} onCancel={onCancel} onApply={handleSubmit} />
        </div>
    );
};

export const useConfigurationHistory = () => {
    const [isShown, setIsShown] = useState(false);

    const toggle = useCallback(() => setIsShown((prev) => !prev), []);

    const SidebarHistory = ({ history = [], hiddenRows = [] }) => {
        return <SidebarOverlay
            title="vaio-provider__configuration-history"
            icon={<ClockIcon />}
            isShown={isShown}
            toggleShown={toggle}
            variants={['right']}
        >
            <VaioConfigurationHistory histories={history} hiddenRows={hiddenRows} />
        </SidebarOverlay>;
    };

    return {
        isShown,
        toggle,
        SidebarHistory
    };
};

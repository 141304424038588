import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { nitroSubscriptionTypeList } from "../../constants/nitroSubscriptionTypeList";

export const Subscriptions = ({ devices, storeLevelSubscription }) => {

    const getSubscriptionName = useCallback(()=> nitroSubscriptionTypeList.find( subscription => parseInt(storeLevelSubscription) === subscription.value),[storeLevelSubscription])

    return (
        <div className="hme-stores-status__list__subscription">
            {
                devices.map((device) => (
                    <div className="hme-stores-status__list__inner-cell" key={device.Device_ID}>
                        {  storeLevelSubscription ? getSubscriptionName()?.label : device.Subscription_Name}
                    </div>
                ))
            }
        </div>
    );
}


Subscriptions.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object).isRequired,
    storeLevelSubscription: PropTypes.number
};

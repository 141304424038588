import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputComponent as Input } from 'components/Inputs';
import { SingleSelect } from 'library/SingleSelect';
import { Loader } from 'library/Loader';
import { ErrorLabel } from 'library/ErrorLabel';
import { useLoadBrands } from 'hooks/useLoadBrands';
import { useLoadCountries } from 'hooks/useLoadCountries';
import { useLoadVaioProviders } from 'hooks/useLoadVaioProviders';
import { filterAXNumberValue, filterNumberValue } from 'helpers/filterInputNumberValues';
import {
    formFieldDataValidator,
    clearFormErrors,
    mapBrandOptions,
    mapCountryOptions,
    mapVaioProvidersOptions,
} from '../helpers';

import './AddStoreForm.scss';

export const AddStoreForm = ({ formErrors, onChange, onOptionsLoading, onFormSubmitEnabled, onFormError, genericFormError }) => {
    const [storeName, setStoreName] = useState('');
    const [storeNumber, setStoreNumber] = useState('');
    const [axCustomerAccountNumber, setAxCustomerAccountNumber] = useState('');
    const [brandId, setBrandId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [providerId, setProviderId] = useState('');

    const { brands, isBrandsLoading } = useLoadBrands();
    const { countries, isCountriesLoading } = useLoadCountries();
    const { vaioProviders = [], isVaioProvidersLoading } = useLoadVaioProviders();

    const { t } = useTranslation();

    const brandOptions = useMemo(() => mapBrandOptions(brands), [brands, mapBrandOptions]);
    const countryOptions = useMemo(() => mapCountryOptions(countries), [countries, mapCountryOptions]);
    const providerOptions = useMemo(
        () => mapVaioProvidersOptions(vaioProviders),
        [vaioProviders, mapVaioProvidersOptions],
    );

    const onAxCustomerAccountNumberChange = useCallback(
        (value) => {
            setAxCustomerAccountNumber(filterAXNumberValue(value));
        },
        [filterAXNumberValue],
    );

    const onStoreNumberChange = useCallback(
        (value) => {
            setStoreNumber(filterNumberValue(value));
        },
        [filterNumberValue],
    );

    const validateInput = useCallback(
        ({ target }) => {
            // clear the errors from previous validation if it has occurred
            const emptyErrors = clearFormErrors(target.name);
            onFormError((currentFormErrors) => ({ ...currentFormErrors, ...emptyErrors }));

            // validate field
            const errors = formFieldDataValidator(target.name, target.value);

            if (errors.status) {
                const { status, ...errorKeys } = errors;
                onFormError((currentFormErrors) => ({ ...currentFormErrors, ...errorKeys }));
            }
        },
        [clearFormErrors, formFieldDataValidator, onFormError],
    );

    const brandLetter = useMemo(() => brands.find((brand) => brand.Id === brandId)?.Letter || '', [brandId]);

    useEffect(() => {
        onChange({
            storeName,
            storeNumber,
            axCustomerAccountNumber,
            brandId,
            brandLetter,
            countryId,
            providerId,
        });
    }, [storeName, storeNumber, axCustomerAccountNumber, brandId, countryId, providerId]);

    const isLoading = isBrandsLoading || isCountriesLoading || isVaioProvidersLoading;

    useEffect(() => {
        onOptionsLoading(isLoading);
    }, [isLoading]);

    // consider required fields only
    const isFormSubmitEnabled = storeNumber && axCustomerAccountNumber && brandId && countryId;

    useEffect(() => {
        onFormSubmitEnabled(isFormSubmitEnabled);
    }, [isFormSubmitEnabled]);

    if (isLoading) {
        return <Loader variants={['invert']} />;
    }

    return (
        <div className="add-store-form hme-components">
            <div className='add-store-generic-error'>{genericFormError ? genericFormError : null}</div>
            <form className="hme-add-store__form">
                <div className="add-store-form__section">
                    <div className="add-store-form__box">
                        <SingleSelect
                            value={brandId}
                            placeholder={t('common__select-option')}
                            label={t('common__brand')}
                            onChange={setBrandId}
                            items={brandOptions}
                            isRequired
                            variants={['no-label']}
                            
                        />
                        {formErrors.brandId && <ErrorLabel>{t(formErrors.brandId)}</ErrorLabel>}
                    </div>
                    <div className="add-store-form__box">
                        <Input
                            name="storeName"
                            label={'common__store__name'}
                            variants={['label-inside-mobile-only']}
                            value={storeName}
                            onChange={setStoreName}
                            maxLength={25}
                            onBlur={validateInput}
                        />
                        {formErrors.storeName && <ErrorLabel>{t(formErrors.storeName)}</ErrorLabel>}
                    </div>
                    <div className="add-store-form__box">
                        <Input
                            name="storeNumber"
                            label={'common__store__number--text'}
                            variants={['label-inside-mobile-only']}
                            value={storeNumber}
                            onChange={onStoreNumberChange}
                            maxLength={50}
                            isRequired
                            onBlur={validateInput}
                        />
                        {formErrors.storeNumber && <ErrorLabel>{t(formErrors.storeNumber)}</ErrorLabel>}
                    </div>
                </div>
                <div className="add-store-form__section">
                    <div className="add-store-form__box">
                        <SingleSelect
                            value={countryId}
                            placeholder={t('common__select-option')}
                            label={t('common__country')}
                            onChange={setCountryId}
                            items={countryOptions}
                            isRequired
                            variants={['no-label']}
                        />
                        {formErrors.countryId && <ErrorLabel>{t(formErrors.countryId)}</ErrorLabel>}
                    </div>
                    <div className="add-store-form__box">
                        <Input
                            name="axCustomerAccountNumber"
                            label={'common__ax-account--customer'}
                            variants={['label-inside-mobile-only']}
                            value={axCustomerAccountNumber}
                            onChange={onAxCustomerAccountNumberChange}
                            maxLength={10}
                            isRequired
                            onBlur={validateInput}
                        />
                        {formErrors.axCustomerAccountNumber && (
                            <ErrorLabel>{t(formErrors.axCustomerAccountNumber)}</ErrorLabel>
                        )}
                        {!formErrors.axCustomerAccountNumber && formErrors.axCustomerAccountNumberInvalid && (
                            <ErrorLabel>{t(formErrors.axCustomerAccountNumberInvalid)}</ErrorLabel>
                        )}
                    </div>
                    <div className="add-store-form__box">
                        <SingleSelect
                            value={providerId}
                            placeholder={t('common__select-option')}
                            label={t('common__vaio-provider')}
                            onChange={setProviderId}
                            items={providerOptions}
                            variants={['no-label']}
                        />
                        {formErrors.providerId && <ErrorLabel>{t(formErrors.providerId)}</ErrorLabel>}
                    </div>
                </div>
            </form>
        </div>
    );
};

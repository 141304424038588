import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { InputComponent as Input } from 'components/Inputs';
import { CenterLoader } from 'components/Common/CenterLoader';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { NotificationsList } from 'library/NotificationsList';
import { Tip } from 'library/Tip';
import { verifyCode, getEmailMask, auth } from './Controller';

import './DFACode.scss';
import { Label } from '../../library/Label';

const DFACodeComponent = () => {
    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef(null);

    const emailMask = useMemo(() => getEmailMask(), []);
    const isSubmittable = useMemo(() => code.length === 6, [code]);

    const { t } = useTranslation();

    const onSubmit = useCallback(async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!isSubmittable) {
            return false;
        }

        setIsLoading(true);

        const result = await verifyCode(code);

        if (!result) {
            setIsLoading(false);
            setCode('');
            return;
        }

        auth();
    }, [code, isSubmittable, setIsLoading]);

    if (isLoading) {
        return (
            <div className='hme-dfa-code'>
                <CenterLoader>{t('common__loading')}</CenterLoader>
            </div>
        );
    }

    return (
        <div className='hme-dfa-code'>
            <NotificationsList className='hme-dfa-code__notifications-list' limit={1} />
            <Title>{t('dfa-code__title')}</Title>
            <div className='hme-dfa-code__info'>
                <Trans i18nKey='dfa-code__info'>
                    As an added account security measure, you’ll need to enter the identification code we’ve just sent to your email <b>{{emailMask}}</b>
                </Trans>
            </div>
            {/* Think about moving to separate component if we will have it out of Login flow */}
            <form className='hme-dfa-code__form' ref={formRef} onSubmit={onSubmit}>
                <div className='hme-dfa-code__form__input__wrapper'>
                    <Label>
                        {t('dfa-code__form__label')}
                        <Tip className='hme-dfa-code__tooltip' container={formRef}>{t('dfa-code__tooltip')}</Tip>
                    </Label>
                    <Input
                        name='DFACode'
                        value={code}
                        maxLength={6}
                        onChange={setCode}
                    />
                </div>
                <Button
                    variants={['submit']}
                    type='submit'
                    disabled={!isSubmittable}
                >
                    {t('dfa-code__form__submit')}
                </Button>
            </form>
        </div>
    );
};

export const DFACode = compose(
    withHMELayout()
)(DFACodeComponent);

/* eslint-disable react/prop-types */
import './TextArea.scss';
import React from 'react';

import './TextArea.scss';

export const TextArea = ({ onChange, rows = 4, cols = 53, maxLength = 100, hideResize = false, isRequired = false, children, ...props }) => {
    return (
        <>
            <span className={`hme-textarea-component-before ${isRequired ? '' : 'hide'}`}>*</span>
            <textarea
                rows={rows}
                onChange={onChange}
                cols={cols}
                className={`leaderboard-desc-textbox hme-input-component ${hideResize && 'textarea-hide-resize'}`}
                maxLength={maxLength}
                {...props}
            >
                {children}
            </textarea>
        </>
    );
};

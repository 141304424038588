/* eslint-disable react/prop-types */
import './SaveCustomMessage.scss';
import React, { useEffect, useState } from 'react';
import { Label } from 'library/Label';
import { SaveFooter } from 'library/SaveFooter';
import { Slider } from 'library/Slider';
import { Title } from 'library/Title';
import customSmackTalkApi from 'api/customSmackTalkApi';
import { empty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { TextAreaWithCharCounter } from './TextAreaWithCharCounter';

const MAX_TEXT_LENGTH = 60;
const customMessageType = 'custom';
const customCategoryId = 4;
const customMessageKey = 'smacktalk__message_custom';

export const SaveCustomMessage = ({ message, onCancel = empty, onSuccess = empty }) => {
    const { t } = useTranslation();
    const createTitle = t('smack-talk__tab__messages-landing--create-custom-message');
    const editTitle = t('smack-talk__tab__save-custom-message--edit-custom-message');
    const sliderLabel = t('smack-talk__tab__save-custom-message--message-status').toUpperCase();
    const textareaPlaceholder = t('smack-talk__tab__save-custom-message--enter-message');
    const createSuccessToster = t('smack-talk__tab__save-custom-message--message-created');
    const editSuccessToster = t('smack-talk__tab__manage-messages--message-saved');

    const messageId = message && message.messageId;
    const isEdit = Boolean(messageId);
    const defaultText = isEdit ? message.message : '';
    const defaultStatus = isEdit ? message.status === true : true;
    const title = isEdit ? editTitle : createTitle;
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [text, setText] = useState(defaultText);
    const [messageStatus, setMessageStatus] = useState(defaultStatus);
    const onTextChange = (value) => {
        setText(value);
    };
    useEffect(() => {
        if (message) {
            setMessageStatus(message.status);
            setText(message.message);
            setSubmitEnabled(false);
        }
    }, [message]);

    useEffect(() => {
        const shouldEnable =
            text && text.length > 0 &&
            (text !== defaultText || messageStatus !== defaultStatus);
        setSubmitEnabled(shouldEnable);
    }, [text, messageStatus]);

    const onSave = async () => {
        try {
            setSubmitEnabled(false);
            const data = {
                'CategoryID': customCategoryId,
                'MessageType': customMessageType,
                'MessageKey': customMessageKey,
                'MessageText': text,
                'newSmacktalkStatus': messageStatus
            };
            if (isEdit) {
                data['MessageID'] = messageId;
                await customSmackTalkApi.updateCustom(data);
            } else {
                await customSmackTalkApi.createCustom(data);
            }
            setText('');
            const successMessage = isEdit ? editSuccessToster : createSuccessToster;
            onSuccess(successMessage);
        } catch (e) {
            console.error(e);
            setSubmitEnabled(true);
        }
    };
    const onSliderChange = (_, checked) => {
        setMessageStatus(checked);
    };
    return (
        <div className="custom-smacktalk-page-create-new-custom-message">
            <Title>{title}</Title>
            <TextAreaWithCharCounter
                rows={7}
                placeholder={textareaPlaceholder}
                onChange={onTextChange}
                value={text}
                maxLength={MAX_TEXT_LENGTH}
            />
            <Label>{sliderLabel}</Label>
            <Slider
                checked={messageStatus}
                onSliderChange={onSliderChange}
                label=' '
            />
            <SaveFooter
                isSubmitEnabled={submitEnabled}
                onCancel={onCancel}
                onSave={onSave}
            />
        </div>
    );
};

import { trimValues } from 'helpers';
import { getUser, editUser, removeUser } from 'services/Users';
import { resendActivationEmail } from 'services/Account';
import { getUserRoles } from 'services/Roles';
import { getGroups } from 'services/Groups';
import { getActivity } from 'services/Users';
import { fetchUserTokenByAdmin, masquaradeUser } from 'services/Auth';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

import { getStoresTree } from '../Common/helpers/getStoresTree';
import { getGroupStores } from '../Common/helpers/getGroupStores';
import { findGroups } from '../Common/helpers/findGroups';
import { trimmedKeys } from '../Common/validators/user';

const ADMIN_USERS_URL = '/admin/settings/users';

export const load = async ({
    userUid,
    setUser,
    setRoles,
    setStoresTree,
    setGroupStores,
    setActivityLogs,
    setIsLoading,
    navigate
}) => {
    setIsLoading(true);

    try {
        const user = await getUser({
            userUid,
        });

        setUser({
            uid: user.uuId,
            userName: user.userEmail,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.userRole,
            stores: user.storeIds,
            isActive: user.isActive,
            isAccountOwner: user.isAccountOwner,
            isTOSAccepted: user.Account_TOS_Agree,
            isVerified: user.isVerified,
        });
    } catch (err) {
        if (err.message === 'common__error--user-not-found') {
            navigate(ADMIN_USERS_URL);
        }

        addErrorNotification(err.message);

        return;
    }

    try {
        const rolesPromise = getUserRoles(userUid);
        const groupsPromise = getGroups(userUid);
        const activityPromise = getActivity({ userUid });

        const [roles, groups, logs] = await Promise.all([
            rolesPromise,
            groupsPromise,
            activityPromise,
        ]);

        setRoles(
            roles.map(({ Role_UID, Role_Name }) => ({
                value: Role_UID,
                text: Role_Name,
            })),
        );

        if (groups) {
            setStoresTree(getStoresTree(groups));
            setGroupStores(getGroupStores(groups));
        } else {
            setStoresTree([]);
            setGroupStores({});
        }
        setActivityLogs(logs);
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsLoading(false);
};

export const saveUser = async (user, groupStores, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await editUser({
            user: {
                ...trimValues(user, trimmedKeys),
                groups: findGroups(user.stores, groupStores),
            },
        });

        navigate(ADMIN_USERS_URL);
        setTimeout(() => {
            addSuccessNotification('add-user__success--updated');
        }, 1000);
    } catch (err) {
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

export const deleteUser = async (userUid, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await removeUser({ userUid });

        navigate(ADMIN_USERS_URL);
        setTimeout(() => {
            addSuccessNotification('common__success--user-deleted');
        }, 1000);
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsLoading(false);
};

export const masqueradeUser = async ({ userEmail, setIsLoading, navigate }) => {
    setIsLoading(true);
    try {
        const result = await fetchUserTokenByAdmin({ userEmail });

        if (!result?.accessToken) {
            return addErrorNotification('common__error--internal-server__p1');
        }

        masquaradeUser(result.accessToken);
        navigate(`/welcome`);
    } catch (err) {
        addErrorNotification('common__error--internal-server__p1');
    }

    setIsLoading(false);
};

export const sendActivationEmail = async ({ userEmail, setIsSendingEmail }) => {
    try {
        setIsSendingEmail(true);

        if (!userEmail) {
            throw new Error('admin__user__no__uuid-error');
        }

        const successMessage = await resendActivationEmail(userEmail);
        addSuccessNotification(successMessage);
    } catch (err) {
        addErrorNotification(err.message);
    }

    setIsSendingEmail(false);
};

import { CommonConstants } from 'Constants';

const { deviceType } = CommonConstants;

const isSubscriptionExist = (deviceDetails, subscriptionId) =>
    deviceDetails.some(({ Device_DeviceType_ID: deviceTypeId }) => deviceTypeId === subscriptionId);

export const getDeviceSubscription = (deviceDetails) => {
    const isNVDEnabled = isSubscriptionExist(deviceDetails, deviceType.evd.id);

    return {
        isNVDEnabled
    };
};

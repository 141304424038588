import React from 'react';
import { ViewEditAction } from '../../Common/List';
import { isAdmin } from 'services/Auth';

const isAdminUser = isAdmin();

const getUrl = (user) => isAdminUser ?  `/admin/settings/users/user?uuid=${user.User_UID}` : `/settings/users/user/${user.User_UID}`;

export const usersToRows = (users, onConfirmModalOpen, hasManageUserAccess) => users.map((user) => ({
    ...user,
    RoleText: user.IsOwner ? 'Account Owner' : user.Role_Name,
    Actions: (!user.IsOwner || isAdminUser) && <ViewEditAction
        url={getUrl(user)}
        isOwner={user.IsOwner}
        onConfirmModalOpen={onConfirmModalOpen}
        uuid={user.User_UID}
        hasManageUserAccess={hasManageUserAccess}
    /> })
);

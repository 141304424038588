/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Label } from 'library/Label';
import { SingleSelect as Select } from 'library/SingleSelect';

import './SubscriptionsBlock.scss';
import { Toggle } from 'library/Toggle';
import { nitroSubscriptions, subscriptionTypeList } from 'pages/Account/helpers';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { CommonConstants } from 'Constants';
import { getTiersByDeviceType } from '../helpers/getTiersByDeviceType';
import { DEFAULT_SUBSCRIPTION } from '../../Admin/hooks/useViewEdit';

const { deviceType, defaultDropdownOption } = CommonConstants;

const subscriptionStatus = [
    {
        text: 'common__yes',
        value: true
    },
    {
        text: 'common__no',
        value: false
    }
];

const generateTierList = (deviceTypeID, tiers) => {
    const filteredTiers = getTiersByDeviceType(deviceTypeID, tiers);

    return filteredTiers.map(({ id, name }) => ({
        value: id,
        text: name
    }));
};

const nitroSubscriptionTypes = subscriptionTypeList.filter((subscriptionType)=> {
    return nitroSubscriptions.includes(subscriptionType.value) && subscriptionType.isNitro;
});

const nitroSubscriptionList = nitroSubscriptionTypes.map(({ label, value })=> {
    return ({
        value,
        text: label
    });
});

const AdminSubscriptionBlock = ({
    nexeoTierList,
    jTechTiersList,
    isJTechCustomerOnlySubscription,
    isNexeoCustomerOnlySubscription,
    isNitroSubscription,
    isNVDEnabled,
    nitroSubscription,
    vaioSubscription,
    onVaioSubChange,
    onStoreSystemTierChange,
    onJTechTierChange,
    subscriptionList,
    nexeoSystemTierID = '',
    jTechTierID,
    isStoreSystemTiersDisabled,
    initialVaioProvider,
    hasManageNitroSubscriptionPermission,
    onNitroSubscriptionChange
}) => {
    const { t } = useTranslation();

    const isNitroOrJtechSubscription = isNitroSubscription || isJTechCustomerOnlySubscription;

    return (
        <div className="hme-admin-store-details__subscription-block">
            <Title variants={['block']}>{t('common__subscriptions-features')}</Title>

            {isNitroOrJtechSubscription && (
                <>
                    <div className="hme-view-edit-store__subscription-value">
                        <Label
                            className="hme-view-edit-store__subscription-value__label"
                        >
                            {t('stores__view--edit--nitro-subscription__title')}
                        </Label>
                        <Select
                            isDisabled={!hasManageNitroSubscriptionPermission}
                            value={nitroSubscription}
                            variants={['label-inside']}
                            label={t('stores__view--edit--nitro-subscription__label')}
                            items={nitroSubscriptionList}
                            onChange={onNitroSubscriptionChange}
                        />
                    </div>
                    <div className="hme-view-edit-store__subscription-value">
                        <Label
                            className="hme-view-edit-store__subscription-value__label"
                        >
                            {t('common__subscription--evd')}
                        </Label>
                        <Toggle value={isNVDEnabled} isDisabled={true}>
                            {subscriptionStatus}
                        </Toggle>
                    </div>
                </>
            )}
            {(isNitroOrJtechSubscription || isNexeoCustomerOnlySubscription) && (
                <>
                    <div className="hme-view-edit-store__subscription-value">
                        <Label
                            className="hme-view-edit-store__subscription-value__label"
                        >
                            {t('stores__view--edit--nexeo-system-tier__title')}
                        </Label>
                        <Select
                            isDisabled={isStoreSystemTiersDisabled}
                            value={nexeoSystemTierID}
                            placeholder={defaultDropdownOption[0].text}
                            onChange={onStoreSystemTierChange}
                            variants={['label-inside']}
                            label={t('stores__view--edit--nexeo-system-tier__label')}
                            items={nexeoTierList}
                        />
                    </div>
                    {initialVaioProvider && (
                        <div className="hme-view-edit-store__subscription-value">
                            <Label className="hme-view-edit-store__subscription-value__label">
                                {t('common__subscription--vaio')}
                            </Label>
                            <Select
                                value={vaioSubscription}
                                onChange={onVaioSubChange}
                                variants={['label-inside']}
                                label={t('vaio_subscription-label')}
                                placeholder={t(initialVaioProvider.name)}
                                items={subscriptionList}
                            />
                        </div>
                    )}
                </>
            )}
            {isNitroOrJtechSubscription && (
                <div className="hme-view-edit-store__subscription-value">
                    <Label
                        className="hme-view-edit-store__subscription-value__label"
                    >
                        {t('common__device__type--link-wear')}
                    </Label>
                    <Select
                        isDisabled={isStoreSystemTiersDisabled}
                        value={jTechTierID}
                        onChange={onJTechTierChange}
                        variants={['label-inside']}
                        label={t('stores__view--edit--linkwear-tier__label')}
                        items={jTechTiersList}
                    />
                </div>
            )}
        </div>
    );
};

const PublicSubscriptionBlock = ({
    isJTechCustomerOnlySubscription,
    isNexeoCustomerOnlySubscription,
    isNitroSubscription,
    isNVDEnabled,
    jTechTiersList,
    jTechTierID,
    nexeoTierList,
    nitroSubscription,
    vaioSubscription,
    subscriptionList,
    nexeoSystemTierID = '',
    initialVaioProvider
}) => {
    const { t } = useTranslation();

    const isNitroOrJtechSubscription = isNitroSubscription || isJTechCustomerOnlySubscription;
    const isNexeoTierSelectorVisible = (isNitroOrJtechSubscription && nexeoSystemTierID) || isNexeoCustomerOnlySubscription;
    const isJtechTierSelectorVisible = isNitroOrJtechSubscription && jTechTierID !== DEFAULT_SUBSCRIPTION.id;

    return (
        <div className="hme-admin-store-details__subscription-block">
            <Title variants={['block']}>{t('common__subscriptions-features')}</Title>

            {isNitroOrJtechSubscription && (
                <>
                    <div className="hme-view-edit-store__subscription-value">
                        <Label
                            className="hme-view-edit-store__subscription-value__label"
                        >
                            {t('stores__view--edit--nitro-subscription__title')}
                        </Label>
                        <Select
                            isDisabled={true}
                            value={nitroSubscription}
                            variants={['label-inside']}
                            label={t('stores__view--edit--nitro-subscription__label')}
                            items={nitroSubscriptionList}
                        />
                    </div>
                    <div className="hme-view-edit-store__subscription-value">
                        <Label
                            className="hme-view-edit-store__subscription-value__label"
                        >
                            {t('common__subscription--evd')}
                        </Label>
                        <Toggle value={isNVDEnabled} isDisabled={true}>
                            {subscriptionStatus}
                        </Toggle>
                    </div>
                </>
            )}

            {isNexeoTierSelectorVisible && (
                <>
                    <div className="hme-view-edit-store__subscription-value">
                        <Label
                            className="hme-view-edit-store__subscription-value__label"
                        >
                            {t('stores__view--edit--nexeo-system-tier__title')}
                        </Label>
                        <Select
                            isDisabled={true}
                            value={nexeoSystemTierID}
                            placeholder={defaultDropdownOption[0].text}
                            variants={['label-inside']}
                            label={t('stores__view--edit--nexeo-system-tier__label')}
                            items={nexeoTierList}
                        />
                    </div>
                    {initialVaioProvider && (
                        <div className="hme-view-edit-store__subscription-value">
                            <Label className="hme-view-edit-store__subscription-value__label">
                                {t('common__subscription--vaio')}
                            </Label>
                            <Select
                                value={vaioSubscription}
                                variants={['label-inside']}
                                label={t('vaio_subscription-label')}
                                placeholder={t(initialVaioProvider.name)}
                                items={subscriptionList}
                                isDisabled={true}
                            />
                        </div>
                    )}
                </>
            )}

            {isJtechTierSelectorVisible && (
                <div className="hme-view-edit-store__subscription-value">
                    <Label
                        className="hme-view-edit-store__subscription-value__label"
                    >
                        {t('common__device__type--link-wear')}
                    </Label>
                    <Select
                        isDisabled={true}
                        value={jTechTierID}
                        variants={['label-inside']}
                        label={t('stores__view--edit--linkwear-tier__label')}
                        items={jTechTiersList}
                    />
                </div>
            )}
        </div>
    );
};

export const SubscriptionsBlock = ({
    isJTechCustomerOnlySubscription,
    isNexeoCustomerOnlySubscription,
    isNitroSubscription,
    isNVDEnabled,
    nitroSubscription,
    vaioSubscription,
    onVaioSubChange,
    onStoreSystemTierChange,
    onJTechTierChange,
    subscriptionList,
    storeSystemTiers = [],
    nexeoSystemTierID = '',
    jTechTierID,
    isStoreSystemTiersDisabled,
    initialVaioProvider,
    hasManageNitroSubscriptionPermission,
    onNitroSubscriptionChange
}) => {
    const isAdmin = baseAuthService.isAdmin();

    const nexeoTierList = generateTierList(deviceType.nexeo.id, storeSystemTiers);
    const jTechTiersList = defaultDropdownOption.concat(generateTierList(deviceType.linkWear.id, storeSystemTiers));

    return isAdmin ? (
        <AdminSubscriptionBlock
            nexeoTierList={nexeoTierList}
            jTechTiersList={jTechTiersList}
            isJTechCustomerOnlySubscription={isJTechCustomerOnlySubscription}
            isNexeoCustomerOnlySubscription={isNexeoCustomerOnlySubscription}
            isNitroSubscription={isNitroSubscription}
            isNVDEnabled={isNVDEnabled}
            nitroSubscription={nitroSubscription}
            vaioSubscription={vaioSubscription}
            onVaioSubChange={onVaioSubChange}
            onStoreSystemTierChange={onStoreSystemTierChange}
            onJTechTierChange={onJTechTierChange}
            subscriptionList={subscriptionList}
            nexeoSystemTierID={nexeoSystemTierID}
            jTechTierID={jTechTierID}
            isStoreSystemTiersDisabled={isStoreSystemTiersDisabled}
            initialVaioProvider={initialVaioProvider}
            hasManageNitroSubscriptionPermission={
                hasManageNitroSubscriptionPermission
            }
            onNitroSubscriptionChange={onNitroSubscriptionChange}
        />
    ) : (
        <PublicSubscriptionBlock
            isNitroSubscription={isNitroSubscription}
            nitroSubscription={nitroSubscription}
            nexeoSystemTierID={nexeoSystemTierID}
            storeSystemTiers={storeSystemTiers}
            isStoreSystemTiersDisabled={isStoreSystemTiersDisabled}
            nexeoTierList={nexeoTierList}
            jTechTiersList={jTechTiersList}
            jTechTierID={jTechTierID}
            onJTechTierChange={onJTechTierChange}

            vaioSubscription={vaioSubscription}
            onVaioSubChange={onVaioSubChange}
            isNVDEnabled={isNVDEnabled}
            subscriptionList={subscriptionList}
            onNitroSubscriptionChange={onNitroSubscriptionChange}
            initialVaioProvider={initialVaioProvider}
            isJTechCustomerOnlySubscription={isJTechCustomerOnlySubscription}
            isNexeoCustomerOnlySubscription={isNexeoCustomerOnlySubscription}
        />
    );
};

SubscriptionsBlock.propTypes = {
    isNVDEnabled: PropTypes.bool.isRequired,
    isNitroSubscription: PropTypes.bool.isRequired,
    isJTechCustomerOnlySubscription: PropTypes.bool.isRequired,
    isNexeoCustomerOnlySubscription: PropTypes.bool.isRequired,
    nitroSubscription: PropTypes.number,
    vaioSubscription: PropTypes.number,
    onVaioSubChange: PropTypes.func,
    subscriptionList: PropTypes.array,
    isVaioAllowed: PropTypes.bool,
    initialVaioProvider: PropTypes.object,
    isStoreSystemTiersDisabled: PropTypes.bool,
    storeSystemTiers: PropTypes.array,
    nexeoSystemTierID: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    jTechTierID: PropTypes.number,
    onStoreSystemTierChange: PropTypes.func,
    onJTechTierChange: PropTypes.func,
    deviceDetails: PropTypes.array,
    hasManageNitroSubscriptionPermission: PropTypes.bool,
    onNitroSubscriptionChange: PropTypes.func,
    isEvdSubscription: PropTypes.bool
};

AdminSubscriptionBlock.propTypes = SubscriptionsBlock.propTypes;
PublicSubscriptionBlock.propTypes = SubscriptionsBlock.propTypes;

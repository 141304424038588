import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Tile } from 'library/Tile';
import { Label } from 'library/Label';
import { Loader } from 'library/Loader';
import { TileListItem } from './TileListItem';

import {
    ADMIN_STORES_EDIT_PAGE,
    STORES_EDIT_PAGE
} from '../../StatusPage/constants';

const getStoreEditURL = ({ isAdminUser, storeUUID, uuid }) => {
    return isAdminUser
        ? `${ADMIN_STORES_EDIT_PAGE}?suid=${storeUUID}&uuid=${uuid}`
        : `${STORES_EDIT_PAGE}?suid=${storeUUID}`;
};

export const TilesList = ({
    stores,
    isLoading,
    uuid,
    isAdminUser,
}) => {
    const { t } = useTranslation();

    return (
        <div className='hme-s-size hme-stores-status--public__tile-list'>
            {
                isLoading && <Loader />
            }
            {
                !isLoading && !stores.length && <Label className='hme-stores-status--public__tile-list__no-records'>
                    {t('common__no-stores--found')}
                </Label>
            }
            {
                stores.map(store => {
                    const {
                        Brand_Name: brandName = '',
                        Store_Name: storeName = '',
                        Store_AddressLine1: storeAddress = '',
                        cityString: cityAndState = '',
                        Store_UID: storeUUID = '',
                        Store_Number: storeNumber = '',
                        Group_Name: leaderboard = '',
                        GroupName: reportGroup = '',
                        Status: { props: { devices = []} = {} } = {}
                    } = store;
                        return (
                            <Tile key={storeUUID}>
                                <TileListItem
                                    storeNumber={storeNumber}
                                    leaderboard={leaderboard}
                                    reportGroup={reportGroup}
                                    devices={devices}
                                    brandName={brandName}
                                    storeName={storeName}
                                    storeAddress={storeAddress}
                                    cityAndState={cityAndState}
                                    storeUUID={storeUUID}
                                    editPageURL={getStoreEditURL({ isAdminUser, storeUUID, uuid })}
                                />
                            </Tile>)
                }
                )
            }
        </div>
    );
};

TilesList.propTypes = {
    stores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

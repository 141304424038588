/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable no-warning-comments, require-jsdoc, class-methods-use-this */
import 'url-search-params-polyfill';
import { ADMIN_ROUTES, COMMON_ROUTES, PARTNER_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import { viewStoreSettingsTaskRegexp, partnersRegexp } from 'constants/pageUrls';
import { Constant } from '@hme-cloud/utility-common';
import { isPartner } from 'services/Auth';

import { Config } from '../../Config';
import AuthenticationService from '../Security/AuthenticationService';
import { CommonConstants } from '../../Constants';

const { BuildVersions } = Constant;

const settingTabs = ['applyDeviceSettings', 'manageDeviceSettings'];

const urlRegexps = [{
    regexp: viewStoreSettingsTaskRegexp,
    menu: 'applyStoreSettings'
}, {
    regexp: partnersRegexp,
    menu: 'partners'
}];

export default class NavigationService {
    constructor() {
        // TODO: don't create new instance, use singleton of the baseAuthService instead
        this.authService = new AuthenticationService(Config.authBaseUrl);
        this.url = this.authService.getColdFusionAppUrl(this.authService.isAdmin());
        this.contextToken = this.authService.getToken();
        this.uuid = this.authService.getUUID();
    }

    getParams() {
        const params = new URLSearchParams(window.location.search);
        return params;
    }

    getUuid() {
        const params = this.getParams();
        const uuid = params.get('uuid');
        return uuid || this.uuid;
    }

    getLoginUrl() {
        // return this.authService.isAdmin() ? '/admin' : '/'
        if (this.authService.isAdmin() || window.location.pathname.indexOf('/admin') > -1) {
            return this.url;
        } else {
            return this.url;
        }
    }

    // = ======** Navigations **===========
    // This method is used to generate the url and send it back to the caller.
    getUrl(menu) {
        return this.getMenu(menu);
    }
    // this method has been used to set the navigation to the prepared url.
    toUrl(menu) {
        window.location.href = this.getMenu(menu);
    }

    // This method to get the sub menu selected for the user or an account.
    getSubMenuUrl(menu, type) {
        const uuid = this.getUuid();
        return this.getMenu(menu, uuid, null, type);
    }

    getPublicSelectedMenu(history = window) {
        const path = history.location.pathname;

        if (path.startsWith('/leaderBoards/templates/')) {
            return 'templates';
        }

        switch (path) {
            case '/settings/applyDeviceSettings':
                return 'applyDeviceSettings';
            case '/connect':
                return 'stores';
            case '/summaryreport':
                return 'reports';
            default:
                return '';
        }
    }

    getPartnerSelectedMenu(history = window) {
        const path = history.location.pathname;

        switch (path) {
            case `/${PARTNER_ROUTES.settingsFull}`:
                return `/${PARTNER_ROUTES.settingsFull}`;
            case `/${PARTNER_ROUTES.stores}`:
                return `/${PARTNER_ROUTES.stores}`;
            case `/${PARTNER_ROUTES.tokenManagement}`:
                return `/${PARTNER_ROUTES.tokenManagement}`;
            default:
                return '';
        }
    }

    // Get the selected menu item for highlighting in the screen.
    // eslint-disable-next-line complexity
    getSelectedMenu() {
        const path = window.location.pathname;
        let page;
        let selectedMenu;
        // TODO: rewrite after urls optimization
        if (this.authService.isAdmin()) {
            const foundPage = urlRegexps.find(({ regexp }) => path.match(regexp));

            if (foundPage) {
                return foundPage.menu;
            }

            page = path.substr(path.lastIndexOf('/') + 1);

            switch (page) {
                case 'outliers':
                    selectedMenu = 'outliersReports';

                    break;
                case 'ghost-cars-report':
                    selectedMenu = 'ghostCarsReport';

                    break;
                case 'speed-of-service':
                    selectedMenu = 'speedOfService';

                    break;
                case 'store-status':
                    selectedMenu = 'storeStatus';

                    break;
                case 'accounts':
                case 'account':
                    selectedMenu = 'accounts';

                    break;
                case 'viewDetail':
                case 'viewdetails':
                case 'connect':
                    if (!this.getUuid()) {
                        selectedMenu = 'systems';
                    } else {
                        selectedMenu = 'users';
                    }
                    break;

                case 'applyDeviceSettingsStatus':
                    selectedMenu = 'applyDeviceSettings';
                    break;

                case 'applyStoreSettingsStatus':
                    selectedMenu = 'applyStoreSettings';
                    break;

                case 'mergeDevices':
                    selectedMenu = 'mergeDevices';
                    break;

                case 'firmwareManagement':
                    selectedMenu = 'firmwareManagement';
                    break;

                case 'applyNexeoDeviceSettings':
                    selectedMenu = 'applyNexeoDeviceSettings';
                    break;

                case 'manageBlockedEmails':
                    selectedMenu = 'manageBlockedEmails';
                    break;

                case 'createVaioProvider':
                case 'vaioProviders':
                case 'editVaioProvider':
                    selectedMenu = 'vaioProviders';
                    break;
                case 'dxsFeeds':
                    selectedMenu = 'dxsFeeds';
                    break;

                default:

                    if (path.includes('/admin/customerNotification')) {
                        return 'customerNotifications';
                    }

                    if (path.includes(ADMIN_ROUTES.tokenManagement)) {
                        return 'tokenManagement';
                    }

                    if (path.includes(ADMIN_ROUTES.createAPIToken)) {
                        return 'tokenManagement';
                    }

                    // DESIGN ISSUE: this method has no way to know if the last part of path is a URL param or submenu of navbar.
                    // This switch case incorrectly assumes end URL param as 'selectedSubmenu', so I added below code check and see if we care about secondToLast URL directory too
                    // TODO: Replace this deviceUpgrades parameter handler with a generic one to handle all cases of this kind of problem
                    const fullPathString = path.substring(path.indexOf('/'), path.lastIndexOf('/'));
                    const parentToBottomDirectory = fullPathString.substr(fullPathString.lastIndexOf('/') + 1);
                    if (parentToBottomDirectory === 'deviceUpgrades') {
                        selectedMenu = 'deviceUpgrades';
                        break;
                    }

                    if (
                        fullPathString.includes('dxsFeeds') &&
                        ['feeds', 'accounts'].includes(parentToBottomDirectory)
                    ) {
                        selectedMenu = 'dxsFeeds';
                        break;
                    }

                    if (fullPathString.includes('firmwareManagement')) {
                        selectedMenu = 'firmwareManagement';
                        break;
                    }

                    selectedMenu = this.authService.getSelectedMenu();
                    break;
            }
        } else if (isPartner()) {
            selectedMenu = Object.values(PARTNER_ROUTES).find((route) => path.includes(route));
        } else {
            // eslint-disable-next-line require-unicode-regexp
            page = path.replace(/^\//, '').split('/')[0];
            selectedMenu = page;

            switch (page) {
                case 'leaderboard':
                    selectedMenu = 'leaderBoards';

                    break;
                case 'summaryreport':
                case 'rawcardatareport':
                    selectedMenu = 'reports';

                    break;
                case 'settings':
                case 'connect':
                    selectedMenu = 'stores';

                    break;
                case 'new-reports':
                case 'new-reports-scheduler':
                    selectedMenu='reports';

                    break;

                case 'accounts':
                    selectedMenu='distributorAccounts';
                    break;

                default:
                    break;
            }
        }
        return selectedMenu;
    }

    // This method used to set the selected menu item for the future reference
    // to identify where the navigation has initiated.
    setSelectedMenu() {
        const page = this.authService.getUrlMenu();
        if (this.authService.isLoggedIn()) {
            if (!this.getUuid()) {
                if (!this.authService.isAdmin()) {
                    this.authService.clearUUID();
                }
                this.authService.setSelectedMenu(page);
            }
            if (settingTabs.includes(page)) {
                this.authService.setSelectedMenu(page);
            }
        }
    }

    /**
     * This method determins if a device supports ssl based on device type.
     * All devices except CU40 support ssl for remoteconnect.
     * @param {string} deviceVersion device main version.
     * @return {string} Returns boolean
     */
    doesDeviceSupportSsl(deviceVersion, modelName) {
        let isSecureDevice = true;
        try {
            // Check if device name is zoom, if device is a zoom and its version is less than
            // 3.0.0 (cu50 entry version) consider it a cu40. CU40 devices do not support
            // remote connect over https.
            if (modelName && deviceVersion && modelName === CommonConstants.deviceType.zoom.name) {
                const entryVersion = CommonConstants.deviceType.zoom.entryVersion;
                const currentDeviceVersion = BuildVersions.formatVersion(deviceVersion);
                const cu40DeviceVersionMax = BuildVersions.formatVersion(entryVersion);
                if (currentDeviceVersion && cu40DeviceVersionMax) {
                    isSecureDevice = BuildVersions.semverGte(currentDeviceVersion, cu40DeviceVersionMax);
                } else {
                    // Older cu40 devices do not provide valid version. Render them over http.
                    // eg. 2.00.338. Related issue: https://hme.atlassian.net/browse/DDM-1454
                    isSecureDevice = false;
                }
            }
        } catch (error) {
            console.log(`doesDeviceSupportSsl: Failed to determine the protocol for this device, render the page over https. ${error}`);
        }
        return isSecureDevice;
    }

    /**
     * Generate a url string according to the passed argument(s) that are relevant to the 'page'.
     * @param {*} page
     * @param {*} uuid
     * @param {*} version
     * @param {*} type
     * @param {*} modelName
     * @return {string}
     */
    getMenu(page, uuid, version = null, type = '', modelName = null) {
        let isAdmin = this.authService.isAdmin() || window.location.pathname.indexOf('/admin') > -1;
        let cfUrl = (isAdmin) ? Config.adminColdFusionUrl : Config.coldFusionUrl;
        let url = '';
        let adminParam = '';
        let token = '';

        if (this.authService.isMasquerade()) {
            let user = this.authService.getProfile();
            let userName = user.name ? user.name : user.User_FirstName + ' ' + user.User_LastName;
            token = 'atoken=' + this.authService.getIdToken() + '&memail=' + encodeURIComponent(user.User_EmailAddress) + '&un=' + userName;
        } else {
            token = 'token=' + this.authService.getToken();
        }

        let isFromCF = Config.coldFusionPages ? Config.coldFusionPages.indexOf(page) > -1 : false;

        switch (page) {
            case 'partners':
                url = '/admin/partners';
                break;
            case PARTNER_ROUTES.stores:
                url = `/${PARTNER_ROUTES.stores}`;
                break;
            case PARTNER_ROUTES.settingsFull:
                url = `/${PARTNER_ROUTES.settingsFull}`;
                break;
            case PARTNER_ROUTES.tokenManagement:
                url = `/${PARTNER_ROUTES.tokenManagement}`;
                break;
            case PARTNER_ROUTES.accountInfoFull:
                url = `/${PARTNER_ROUTES.accountInfoFull}`;
                break;
            case 'systems':
                url = isFromCF ? cfUrl + '?pg=SettingsDevices&path=Main&' + token : '/admin/systems';
                break;
            case 'stores':
                if (uuid) {
                    url = isFromCF ? cfUrl + '?pg=SettingsStores&path=Users&uuid=' + uuid + '&' + token : '/settings/stores?uuid=' + uuid;
                } else {
                    url = isFromCF ? cfUrl + '?pg=SettingsStores&path=Main&' + token : (isAdmin ? '/admin/settings/stores' : '/settings/stores');
                }
                break;
            case 'lockDeviceSettings':
                url = isAdmin ? `/settings/lockDeviceSettings?uuid=${uuid}` : '/settings/lockDeviceSettings';
                break;
            case 'users':
                if (uuid) {
                    url = isFromCF ? cfUrl + '?pg=SettingsUsers&path=Users&uuid=' + uuid + '&' + token : '/settings/users?uuid=' + uuid;
                } else {
                    url = isFromCF ? cfUrl + '?pg=SettingsUsers&path=Main&' + token : (isAdmin ? '/admin/settings/users' : '/settings/users');
                }
                break;
            case 'roles':
                if (uuid) {
                    url = isFromCF ? cfUrl + '?pg=SettingsRoles&path=Users&uuid=' + uuid + '&' + token : '/settings/roles?uuid=' + uuid;
                } else {
                    url = isFromCF ? cfUrl + '?pg=SettingsRoles&' + token : '/settings/roles';
                }
                break;
            case 'dashboard':
                if (uuid) {
                    url = isFromCF ? cfUrl + '?pg=Dashboard&path=Users&uuid=' + uuid + '&' + token : '/dashboard?uuid=' + uuid;
                } else {
                    url = isFromCF ? cfUrl + '?pg=Dashboards&' + token : '/dashboard';
                }
                break;
            case 'reports__multiStore':
            case 'reports__title-multi':
                if (uuid) {
                    url = '/new-reports/multi?uuid=' + uuid;
                } else {
                    url = '/new-reports/multi';
                }
                break;
            case 'reports':
                url = COMMON_ROUTES.overview;
                break;
            case 'reports__singleStore':
            case 'reports__title-single':
                if (uuid) {
                    url = '/new-reports/single?uuid=' + uuid;
                } else {
                    url = '/new-reports/single';
                }
                break;
            case 'reports__trends-dashboard':
                if (uuid) {
                    url = '/new-reports/trendsHistory?uuid=' + uuid;
                } else {
                    url = '/new-reports/trendsHistory';
                }
                break;
            case 'reports__trends':
                if (uuid) {
                    url = '/new-reports/trends?uuid=' + uuid;
                } else {
                    url = '/new-reports/trends';
                }
                break;
            case 'admin__hme-reports__sub-header--outliers':
            case 'reports__title-outliers-client':
                if (uuid) {
                    url = '/new-reports/outliers-client?uuid=' + uuid;
                } else {
                    url = '/new-reports/outliers-client';
                }
                break;
            case 'reports__title-performance-analysis':
                if (uuid) {
                    url = '/new-reports/performance-analysis?uuid=' + uuid;
                } else {
                    url = '/new-reports/performance-analysis';
                }
                break;
            case 'reports__title-self-service':
                if (uuid) {
                    url = '/new-reports/self-service?uuid=' + uuid;
                } else {
                    url = '/new-reports/self-service';
                }
                break;
            case 'reports__rawData':
            case 'reports__title-rcd':
                if (uuid) {
                    url = '/new-reports/rcd?uuid=' + uuid;
                } else {
                    url = '/new-reports/rcd';
                }
                break;
            case 'reports__scheduler':
                if (uuid) {
                    url = '/new-reports-scheduler?uuid=' + uuid;
                } else {
                    url = '/new-reports-scheduler';
                }
                break;
            case 'accounts':
            case 'myAccount':
                url = isFromCF ? cfUrl + (isAdmin ? '?pg=SettingsAccounts&' : '?pg=SettingsAccount&') + token : (isAdmin ? '/admin/accounts' : `/${PUBLIC_ROUTES.account}`);
                break;
            case PUBLIC_ROUTES.companyInfo:
                url = `/${PUBLIC_ROUTES.companyInfo}`;
                break;
            case PUBLIC_ROUTES.brandSharing:
                url = `/${PUBLIC_ROUTES.brandSharing}`;
                break;
            case 'account':
                url = isAdmin ? `/admin/account?uuid=${uuid}&type=${type}` : `/accounts/account?type=${type}&auuid=${uuid}`;
                break;
            case 'display':
                url = isFromCF ? cfUrl + '?pg=Settings&' + token : '/settings/display';
                break;
            case 'leaderBoards':
                url = isFromCF ? cfUrl + '?pg=Leaderboard&st=Edit&' + token : '/leaderBoards/LBGroups';
                break;
            case 'leaderboard':
                url = isFromCF ? cfUrl + '?pg=Leaderboard&st=Edit&' + token : '/leaderboard';
                break;
            case 'leaderboardgroup':
                url = isFromCF ? cfUrl + '?pg=SettingsGroups&' + token : '/settings/stores/leaderBoardGroups';
                break;
            case 'help':
                url = isFromCF ? cfUrl + '?pg=Help&' + token : '/help';
                break;
            case 'welcome':
                url = isFromCF ? cfUrl + '?pg=SettingsStores&path=Main&' + token : '/welcome';
                break;
            case 'manageDeviceSettings':
                url = isFromCF ? cfUrl + '?pg=manageDeviceSettings&' + token : (isAdmin ? '/admin/manageDeviceSettings' : '/settings/manageDeviceSettings');
                break;
            case 'applyDeviceSettings':
                url = isFromCF ? cfUrl + '?pg=applyDeviceSettings&' + token : (isAdmin ? '/admin/applyDeviceSettingsStatus' : '/settings/applyDeviceSettingsStatus');
                break;
            case 'applyNexeoDeviceSettings':
                url = isFromCF ? cfUrl + '?pg=applyNexeoDeviceSettings&' + token : (isAdmin ? '/admin/applyNexeoDeviceSettings' : '/welcome');
                break;
            case 'connect':
                let adminParam = (isAdmin) ? '&a=true' : '&a=false';
                // Check if device works fine over https during remote connect.
                // We have observed that on CU40 devices, report generation fails
                // during remote connect over https. Hence for such devices we render
                // remote connect page over http.
                // related issue: https://hme.atlassian.net/browse/DDM-1438
                const supportsSsl = this.doesDeviceSupportSsl(version, modelName);

                url = supportsSsl ? '/connect?duid=' + uuid : window.location.origin.replace('https', 'http') + '/connect?duid=' + uuid + '&' + token + adminParam;
                break;
            case 'reboot':
                url = isFromCF ? cfUrl + '?pg=SettingsDevices&st=reboot&duid=' + uuid + '&' + token : '/reboot?duid=' + uuid;
                break;
            case 'reconnect':
                url = isFromCF ? cfUrl + '?pg=SettingsDevices&st=reconnect&duid=' + uuid + '&' + token : '/reconnect?duid=' + uuid;
                break;
            case 'upgrade':
                url = isFromCF ? cfUrl + `?pg=SettingsDevices&st=upgrade&duid=${uuid}&model=${modelName}&vz=${version}&${token}` : `/upgrade?duid=${uuid}&model=${modelName}&vz=${version}`;
                break;
            case 'smackTalk':
                url = isFromCF ? cfUrl + '?pg=Leaderboard&st=Edit&' + token : '/leaderBoards/smackTalk/send';
                break;
            case 'user':
                url = '/admin/settings/users/user?uuid=' + uuid;
                break;
            case 'deviceUpgrades':
                url = '/admin/settings/deviceUpgrades';
                break;
            case 'applyStoreSettings':
                url = isFromCF ? cfUrl + '?pg=applyDeviceSettings&' + token : (isAdmin ? '/admin/applyStoreSettingsStatus' : '/welcome');
                break;
            case 'mergeDevices':
                url = isFromCF ? cfUrl + '?pg=mergeDevices&' + token : (isAdmin ? '/admin/mergeDevices' : '/settings/mergeDevices');
                break;
            case 'firmwareManagement':
                url = isFromCF ? cfUrl + '?pg=firmwareManagement&' + token : (isAdmin ? '/admin/firmwareManagement' : '/welcome');
                break;
            case 'manageBlockedEmails':
                url = isFromCF ? cfUrl + '?pg=manageBlockedEmails&' + token : (isAdmin ? '/admin/manageBlockedEmails' : '/welcome');
                break;
            case 'vaioProviders':
                url = isFromCF ? cfUrl + '?pg=vaioProviders&' + token : (isAdmin ? '/admin/vaioProviders' : '/welcome');
                break;
            case 'dxsFeeds':
                url = isFromCF ? cfUrl + '?pg=dxsFeeds&' + token : (isAdmin ? '/admin/dxsFeeds' : '/welcome');
                break;
            case 'distributorAccounts': {
                url = `/accounts`;
                break;
            }
            case 'text-connect': {
                url = `/text-connect`;
                break;
            }
            case 'hmeReports':
            case 'outliersReports':
                url = '/admin/reports/outliers';

                break;
            case 'ghostCarsReport':
                url = '/admin/reports/ghost-cars-report';

                break;
            case 'speedOfService':
                url = '/admin/reports/speed-of-service';

                break;
            case 'storeStatus':
                url = '/admin/reports/store-status';
                break;
            case 'customerNotifications': {
                url = ADMIN_ROUTES.customerNotification;
                break;
            }
            case 'tokenManagement': {
                url = ADMIN_ROUTES.tokenManagement;
                break;
            }
            default:
                url = '';
        }
        return url;
    }

    isLoginPage() {
        return window.location.pathname === '/admin' || window.location.pathname === '/';
    }
}

export const navigationService = new NavigationService();

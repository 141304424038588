import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Form } from '../Form';

import './Client.scss';

export const Client = ({ className, ...loginFormProps }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-client-login-page', className)}>
            <Form {...loginFormProps} />
            <Link to={ loginFormProps.isPartner ? '/partner/manageAccount/forgotpassword' : '/manageAccount/forgotpassword'} className='hme-client-login-page__forgot-link'>{t('login__forget-pass-link')}</Link>
        </div>
    );
};

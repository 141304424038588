import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { isAdmin } from 'services/Auth';
import { ADMIN_ROUTES, PUBLIC_ROUTES } from 'constants/routes';

import './StoreNumberLink.scss';

const baseHref = isAdmin() ? ADMIN_ROUTES.storeSettings : PUBLIC_ROUTES.storeSettings;

export const StoreNumberLink = ({ storeUID, children, className = '', ...props }) => {
    const href = useMemo(() => `${baseHref}?suid=${storeUID}`, [storeUID]);

    return (
        <Link
            to={href}
            className={classNames('store-number-link', className)}
            {...props}
        >
            {children}
        </Link>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { addErrorNotification, addSuccessNotification, toast } from 'services/Notifications';
import { changePassword } from 'services/Partner';
import { ASYNC_STATUS, useAsync } from 'hooks/useAsync';
import { COMMON_ROUTES } from 'constants/routes';

const passwordSuccessToastId = 'passwordSuccessToastId';

const INITIAL_PASSWORD_VALUES = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
};
export const usePasswordChange = (initialValues = INITIAL_PASSWORD_VALUES) => {
    const { run, status } = useAsync();
    const [values, setValues] = useState(initialValues);
    const navigate = useNavigate();

    const handleValueUpdate = useCallback(({ name, value }) => {
        setValues((currentValues) => ({
            ...currentValues,
            [name]: value
        }));
    }, []);

    const updatePassword = useCallback(async () => {
        try {
            await run(changePassword(values));
            addSuccessNotification('profile__account__new-user--success', { autoClose: 5000, toastId: passwordSuccessToastId });
        } catch (err) {
            addErrorNotification(err.message, { autoClose: 5000 });
        }
    }, [run, values]);

    useEffect(() => {
        const unsubscribe = toast.onChange(({ type, status: toastStatus, id }) => {
            if (type === toast.TYPE.SUCCESS && toastStatus === 'removed' && id === passwordSuccessToastId) {
                navigate(`/${COMMON_ROUTES.welcome}`);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate]);

    const isPasswordUpdateInProgress = status === ASYNC_STATUS.PENDING;

    return {
        values,
        setValues,
        handleValueUpdate,
        updatePassword,
        isPasswordUpdateInProgress
    };
};

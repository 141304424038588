import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';

import { CenterLoader } from 'components/Common/CenterLoader';
import { NotificationsList } from 'library/NotificationsList';
import { getIp, isAdmin, isPartner, isLoggedIn, clearAuthData } from 'services/Auth';
import { addNotification, dismissNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { login, checkTimeout, getLoginAttempts } from './Controller';
import { Admin } from './Admin';
import { Client } from './Client';
import { defaultNotificationOptions } from 'constants/login';

import './Login.scss';
//We have to include that css file because it contains styles that are out of login page scope
import 'components/Security/Login.css';

import { useLocation, useNavigate } from 'react-router-dom';

const FormComponent = cond([
    [({ isLoading }) => isLoading, ({ t }) => (<CenterLoader>{t('common__loading')}</CenterLoader>)],
    [({ isAdmin }) => isAdmin, (props) => <Admin {...props} />],
    [T, (props) => <Client {...props} />]
]);

export const Login = () => {
    const [ipLoadingPromise, setIpLoadingPromise] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [isExceeded, setIsExceeded] = useState(false);
    const [maxAttempts, setMaxAttempts] = useState(5);
    const [isAdminState, setIsAdminState] = useState(isAdmin);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isPartnerState = useMemo(() => isPartner(), []);

    useEffect(() => {
        if (!isLoggedIn() && isAdminState && !location.pathname.includes('/admin')) {
            clearAuthData();
            setIsAdminState(false);
        }
        else if(!isLoggedIn() && !isAdminState && location.pathname.includes('/admin')){
            clearAuthData();
            setIsAdminState(true);
        }
    }, [isAdminState, location]);

    useEffect(() => {
        const getIpPromise = getIp();
        setIpLoadingPromise(getIpPromise);
    }, [setIpLoadingPromise]);

    useEffect(() => {
        setIsExceeded(!checkTimeout());
        setAttempts(getLoginAttempts());
    }, [setIsExceeded, setAttempts]);

    const onLogin = useCallback(async (formData) => {
        setIsLoading(true);
        dismissNotification();

        const redirectUrl = (location.state && location.state.referrer) || '/welcome';

        try {
            const ip = await ipLoadingPromise;
            const loginResult = await login(formData, redirectUrl, ip);

            if (loginResult.success) {
                if (loginResult.redirectTo) {
                    navigate('/validateDfaAuth', { state: location.state || {} });
                }

                return;
            }

            setAttempts(loginResult.loginAttempts);
            setMaxAttempts(loginResult.maxAttempts);
            setIsExceeded(loginResult.isExceeded);

            setIsLoading(false);
        } catch (err) {
            // display INTERNAL SERVER ERROR- 500 only for public portal login
            if (!isAdminState)
                addNotification({
                    type: NOTIFICATION_TYPES.ERROR,
                    message: ['common__error--internal-server']
                }, defaultNotificationOptions);

            setIsLoading(false);
        }
    }, [ipLoadingPromise, location, setIsLoading, setAttempts]);

    return (
        <div className="hme-login-page-container">
            {!isLoading && <NotificationsList className="hme-login-page-notifications-list" limit={1} />}
            <FormComponent
                className="hme-login-page"
                isAdmin={isAdminState}
                isLoading={isLoading}
                t={t}
                attempts={attempts}
                maxAttempts={maxAttempts}
                isExceeded={isExceeded}
                onLogin={onLogin}
                isPartner={isPartnerState}
            />
        </div>
    );
};

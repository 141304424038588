import React from 'react';
import { useTranslation } from 'react-i18next';

import { navigationService } from '../../Common/NavigationServices';
import { getAdminReportsTab } from './getAdminReportsTab';

const AdminReportsSubHeader = () => {
    const { t } = useTranslation();
    const getSelectedMenu = navigationService.getSelectedMenu();
    const menus = getAdminReportsTab();
    const captions = {
        'outliersReports': t('admin__hme-reports__sub-header--outliers'),
        'ghostCarsReport': t('admin__hme-reports__sub-header--ghost-cars'),
        'speedOfService': t('admin__hme-reports__sub-header--speed-of-service'),
        'storeStatus': t('admin__hme-reports__sub-header--store-status')
    };
    const active = (menu) => getSelectedMenu === menu ? 'active_tab_sub' : '';

    return (menus.includes(getSelectedMenu) &&
        <div>
            <div className="subMenu menuBar ">
                <div className="subHeaderSection">
                    <ul className="subHeaders">
                        {menus.map((menu, index) => (
                            <li key={index}>
                                <a
                                    className={'headerMenu ' + active(menu)}
                                    href={navigationService.getSubMenuUrl(menu)}
                                >{captions[menu]}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AdminReportsSubHeader;

import React, { useCallback } from 'react';
import { T, cond, isEmpty } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SaveFooter } from 'library/SaveFooter';
import { PasswordChangeField } from 'library/PasswordChangeField';
import { InputWithError } from 'library/TextInput';
import { PageContentLayout } from 'library/PageContentLayout/PageContentLayout';
import { COMMON_ROUTES } from 'constants/routes';
import { CenterLoader } from 'components/Common/CenterLoader';

import { usePasswordChange, useProfileData } from './hooks';

import './Profile.scss';

const ProfileComponent = ({
    values,
    handleValueUpdate,
    updatePassword,
    isPasswordUpdateInProgress,
    accountData,
    handleAccountDataUpdate,
    updateAccountData,
    updateAccountIsLoading,
    accountDataErrors
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleCancelClick = useCallback(() => {
        navigate(`/${COMMON_ROUTES.welcome}`);
    }, []);

    const hasNoErrors = isEmpty(accountDataErrors);

    return (
        <PageContentLayout.Layout>
            <PageContentLayout.Content>
                <PageContentLayout.PageTitle title={t('partner__account-info')} />

                <PageContentLayout.FieldsSection
                    title="profile__account__login-details__title"
                    showNumber={false}
                    number={1}
                >
                    <InputWithError
                        label="common__user__username"
                        onChange={handleAccountDataUpdate}
                        value={accountData.userEmail}
                        name="userEmail"
                        universalOnChange
                        isRequired
                        error={accountDataErrors.userEmail}
                    />

                    <PasswordChangeField
                        fields={values}
                        onFieldsChange={handleValueUpdate}
                        onSubmit={updatePassword}
                        isPasswordChangeInProgress={
                            isPasswordUpdateInProgress
                        }
                    />
                </PageContentLayout.FieldsSection>

                <PageContentLayout.FieldsSection
                    title="my-account__account-info__user-info"
                    showNumber={false}
                    number={2}
                    className="hme-page-layout__fields-section--borderless"
                >
                    <InputWithError
                        label="common__user__first-name"
                        onChange={handleAccountDataUpdate}
                        value={accountData.firstName}
                        error={accountDataErrors.firstName}
                        name="firstName"
                        universalOnChange
                        isRequired
                    />
                    <InputWithError
                        label="common__user__last-name"
                        onChange={handleAccountDataUpdate}
                        value={accountData.lastName}
                        error={accountDataErrors.lastName}
                        name="lastName"
                        universalOnChange
                        isRequired
                    />
                    <InputWithError
                        label="common__phone-number"
                        onChange={handleAccountDataUpdate}
                        value={accountData.officeNumber}
                        error={accountDataErrors.officeNumber}
                        name="officeNumber"
                        universalOnChange
                    />
                    <InputWithError
                        label="common__mobile-phone"
                        onChange={handleAccountDataUpdate}
                        value={accountData.mobilePhone}
                        error={accountDataErrors.mobilePhone}
                        name="mobilePhone"
                        universalOnChange
                    />
                </PageContentLayout.FieldsSection>
            </PageContentLayout.Content>

            <SaveFooter
                onSave={updateAccountData}
                onCancel={handleCancelClick}
                isSubmitEnabled={!updateAccountIsLoading && hasNoErrors}
            />
        </PageContentLayout.Layout>
    );
};

const PartnerPersonalInfoUI = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <CenterLoader>
                <Trans i18nKey="common__loading" />
            </CenterLoader>
        )
    ],
    [T, (props) => <ProfileComponent {...props} />]
]);

export const PartnerPersonalInfo = () => {
    const {
        isLoading,
        accountData,
        handleAccountDataUpdate,
        accountDataErrors,
        updateAccountData,
        updateAccountIsLoading
    } = useProfileData();

    const {
        values,
        handleValueUpdate,
        updatePassword,
        isPasswordUpdateInProgress
    } = usePasswordChange();

    return (
        <PartnerPersonalInfoUI
            isLoading={isLoading}
            accountData={accountData}
            handleAccountDataUpdate={handleAccountDataUpdate}
            accountDataErrors={accountDataErrors}
            values={values}
            handleValueUpdate={handleValueUpdate}
            updatePassword={updatePassword}
            updateAccountData={updateAccountData}
            updateAccountIsLoading={updateAccountIsLoading}
            isPasswordUpdateInProgress={isPasswordUpdateInProgress}
        />
    );
};

import React from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { compose } from 'ramda';
import 'url-search-params-polyfill';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import { PermissionFor } from 'actions/permissions';
import { hasLeaderboardAccess } from 'helpers/access';
import { JSONParseSafe } from 'helpers/JSONParseSafe';
import HmeHeader from '../Header/HmeHeader';
import SettingsHeader from '../Header/SettingsHeader';
import AdminSubHeader from '../Header/adminSubHeader';
import AdminReportsSubHeader from '../Header/AdminReportsSubHeader/AminReportsTab';
import Footer from '../Footer/Footer';
import { Config } from '../../Config';
import AuthenticationService from '../Security/AuthenticationService';
import Api from '../../Api';
import { LeaderBoardsHeader } from '../Header/LeaderBoardsHeader';
import DeviceSettingsSubHeader from '../Header/DeviceSettingsSubHeader/DeviceSettingsSubHeader';
import DeviceUpgradesSubHeader from '../Header/DeviceUpgradesSubHeader/DeviceUpgradesSubHeader';
import ToolsSubHeader from 'components/Header/ToolsSubHeader/ToolsSubHeader';
import { getSelectedUUID, setSelectedUUID } from 'services/Auth';
import { IdleTimer } from './IdleTimer';

import { withReactRouter } from 'HOCs/withReactRouter';
import PropTypes from 'prop-types';
import UpdatedTOSModal from './UpdatedTOSModal';
import { isDFARequired, isMasquerade as isUserMasquerade, isPartner as isUserPartner, isAdmin as isUserAdmin } from 'services/Auth';
import { ADMIN_ROUTES, COMMON_ROUTES, PARTNER_ROUTES } from 'constants/routes';

import './Common.css';

const hasAccessLeaderboardPage = hasLeaderboardAccess();
const hasAccessContestPage = hasLeaderboardAccess(PermissionFor.Contest);
const hasAccessAvatarBoxPage = hasLeaderboardAccess(PermissionFor.Avatar);
const hasAccessAwardsPage = hasLeaderboardAccess(PermissionFor.Award);
const hasAccessLBTemplatesPage = hasLeaderboardAccess(PermissionFor.LBTemplates);

const getRouteClassNamesList = (isAdmin, isGamified = false) => [
    {
        path: '/leaderBoards/LBTemplatesList/',
        divClassName: hasAccessLeaderboardPage ? 'leader-board-templates-body' : 'hme-default-page',
    },
    {
        path: '/leaderBoards/LBTemplatesList',
        divClassName: hasAccessLBTemplatesPage ? 'hmeBody' : 'hme-default-page',
    },
    {
        path: '/help',
        divClassName: 'helpSection'
    },
    {
        path: '/welcome',
        divClassName: isAdmin ? 'welcomeAdmin' : 'welcome'
    },
    {
        path: '/manageDeviceSettings',
        divClassName: 'hme-default-page'
    },
    {
        path: '/vaioProviders',
        divClassName: 'hme-default-page'
    },
    {
        path: '/createVaioProvider',
        divClassName: 'hme-default-page'
    },
    {
        path: '/editVaioProvider',
        divClassName: 'hme-default-page'
    },
    {
        path: '/applyDeviceSettings',
        divClassName: 'applyDeviceSettings'
    },
    {
        path: '/applyStoreSettings',
        divClassName: 'hme-default-page'
    },
    {
        path: '/storeSettingsTask/view',
        divClassName: 'hme-default-page'
    },
    {
        path: '/applyNexeoDeviceSettings',
        divClassName: 'applyNexeoDeviceSettings'
    },
    {
        path: 'leaderBoards/awards',
        divClassName: hasAccessAwardsPage ? (isGamified ? 'welcome' : 'hmeBody') : 'hme-default-page',
    },
    {
        path: '/deviceUpgrades',
        divClassName: 'hme-default-page'
    },
    {
        path: '/leaderBoards/LBGroups/lbGroup',
        divClassName: 'leaderboard-group'
    },
    {
        path: '/leaderBoards/LBGroups',
        divClassName: hasAccessLeaderboardPage ? 'hmeBody' : 'hme-default-page',
    },
    {
        path: '/leaderBoards/announcementSettings',
        divClassName: 'hme-default-page'
    },
    {
        path: '/leaderBoards/contests',
        divClassName: hasAccessContestPage ? '' : 'hme-default-page',
    },
    {
        path: '/leaderBoards/avatarBox',
        divClassName: hasAccessAvatarBoxPage ? 'hmeBody' : 'hme-default-page',
    },
    {
        path: '/new-reports-scheduler/create',
        divClassName: 'leaderboard-group'
    },
    {
        path: '/new-reports-scheduler/edit',
        divClassName: 'leaderboard-group'
    },
    {
        path: COMMON_ROUTES.overview,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.multi,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.single,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.trendsHistory,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.trends,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES['outliers-client'],
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES['performance-analysis'],
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES['self-service'],
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.rcd,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.scheduledReports,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.scheduledReportCreate,
        divClassName: 'hme-default-page'
    },
    {
        path: COMMON_ROUTES.scheduledReportEdit,
        divClassName: 'hme-default-page'
    },
    {
        path: '/manageBlockedEmails',
        divClassName: 'hme-default-page'
    },
    {
        path: 'accounts/users/add-user',
        divClassName: 'hme-default-page'
    },
    {
        path: 'accounts/users/edit-user',
        divClassName: 'hme-default-page'
    },
    {
        path: '/accounts',
        divClassName: 'hme-default-page'
    },
    {
        path: '/leaderBoards/smackTalk/manage',
        divClassName: 'hme-default-page'
    },
    {
        path: '/leaderBoards/smackTalk/send',
        divClassName: 'hme-default-page'
    },
    {
        path: '/leaderBoards/smackTalk/history',
        divClassName: 'hme-default-page'
    },
    {
        path: '/settings/stores/viewDetail',
        divClassName: 'hmeBody',
    },
    {
        path: '/settings/stores/viewdetails',
        divClassName: 'hmeBody',
    },
    {
        path: '/settings/stores/leaderBoardGroups',
        divClassName: 'hmeBody',
    },
    {
        path: '/settings/stores',
        divClassName: 'hme-default-page',
    },
    {
        path: '/admin/settings/users/user',
        divClassName: 'hme-default-page',
    },
    {
        path: '/settings/users/user',
        divClassName: 'hme-default-page',
    },
    {
        path: '/settings/roles/editRoles',
        divClassName: 'hmeBody',
    },
    {
        path: '/settings/roles/addRoles',
        divClassName: 'hmeBody',
    },
    {
        path: '/settings/roles',
        divClassName: 'hme-default-page',
    },
    {
        path: '/settings/display',
        divClassName: 'hme-default-page',
    },
    {
        path: '/admin/settings/users',
        divClassName: 'hme-default-page',
    },
    {
        path: '/settings/users',
        divClassName: 'hme-default-page',
    },
    {
        path: '/profile',
        divClassName: 'hme-default-page',
    },
    {
        path: '/admin/customerNotification',
        divClassName: 'hme-default-page',
    },
    {
        path: '/notifications',
        divClassName: 'hme-default-page',
    },
    {
        path: ADMIN_ROUTES.tokenManagement,
        divClassName: 'hme-default-page',
    },
    {
        path: ADMIN_ROUTES.createAPIToken,
        divClassName: 'hme-default-page',
    },
    {
        path: '/admin/firmwareManagement',
        divClassName: 'hme-default-page',
    },
    {
        path: '/createAPIToken',
        divClassName: 'hme-default-page'
    },
    {
        path: 'admin/partners',
        divClassName: 'hme-default-page'
    },
    {
        path: PARTNER_ROUTES.accountInfoFull,
        divClassName: 'hme-default-page'
    },
    {
        path: PARTNER_ROUTES.settingsFull,
        divClassName: 'hme-default-page hme-partner-providers__page'
    },
    {
        path: PARTNER_ROUTES.stores,
        divClassName: 'hme-default-page'
    },
    {
        path: PARTNER_ROUTES.tokenManagement,
        divClassName: 'hme-default-page'
    },
    {
        path: '/',
        divClassName: 'hmeBody'
    }
];

const getPathName = (path, search) => {
    const searchText = search.toString();

    return `${path}?${searchText}`;
};

const AUTO_LOGOUT_INFO_STORAGE_KEY = 'autoLogoutInfo';
const AUTO_LOGOUT_SYNC_TIMERS_MS = 1000;

const storeAutoLogoutInfo = (payload) => {
    localStorage.setItem(AUTO_LOGOUT_INFO_STORAGE_KEY, JSON.stringify(payload));
}

const removeAutoLogoutInfo = () => {
    localStorage.removeItem(AUTO_LOGOUT_INFO_STORAGE_KEY);
}

/**
 * This component wraps all the necessary headers and footer to the passed component.
 */
class Layout extends React.Component {
    /**
     * Set initial state
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.api = new Api();
        this.authService = new AuthenticationService(Config.authBaseUrl);

        this.state = {
            modalIsOpen: false,
            isAdmin: isUserAdmin(),
            isPartner: isUserPartner(),
            isMasquerade: isUserMasquerade()
        };

        this.autologoutConfirmationTimer = null;

        this.closeModal = this.closeModal.bind(this);
        this.logout = this.logout.bind(this);
    }

    /**
     * Call prepareProfile
     */
    UNSAFE_componentWillMount() {
        this.prepareProfile();
    }

    /**
     * cleanup for timer. layout is generic, so better here than in compWillUnmount,
     */
    UNSAFE_componentWillUpdate() {
        if (!this.authService.isLoggedIn() && this.autologoutConfirmationTimer)
            clearTimeout(this.autologoutConfirmationTimer);
    }

    componentDidMount(){
        window.addEventListener('storage', (event) => {
            if (event.key === AUTO_LOGOUT_INFO_STORAGE_KEY) {
                const autoLogoutInfo = JSONParseSafe(event.newValue);

                // handle the case when a user presses the modal stay logged in button
                if (autoLogoutInfo?.isAutoLogoutCancelled === true) {
                    clearTimeout(this.autologoutConfirmationTimer);
                    this.setState({ modalIsOpen: false });
                }

                // handle the case when a user presses the modal close button or ignores the modal
                if (autoLogoutInfo?.isAutoLogoutCancelled === false) {
                    clearTimeout(this.autologoutConfirmationTimer);
                    window.location.href = `${autoLogoutInfo.isAdmin ? '/admin' : ''}/logout`;
                }
            }
        });
    }

    /**
     * Get all the details from params and update all the metadata related to session.
     */
    prepareProfile() {
        const { queryParams, location } = this.props;

        const contextToken = queryParams.get('token');
        const uuid = queryParams.get('uuid');
        const duid = queryParams.get('duid');
        const idToken = queryParams.get('atoken');
        const userId = queryParams.get('memail');
        const GroupUID = queryParams.get('GroupUID');
        const isDefault = queryParams.get('isDefault');
        const ia = queryParams.get('ia');
        let headers = {};

        if (contextToken) {
            this.authService.setToken(contextToken);
        }

        if (idToken) {
            this.authService.setTokens(idToken, contextToken);
            headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idToken
            };
        }

        const { pathname: path } = location;

        const search = new URLSearchParams('');

        if (userId) {
            const user = {
                username: userId
            };
            const url = Config.authBaseUrl + Config.tokenPath;
            this.api.postData(url, user, (data) => {
                if (data && data.accessToken) {
                    this.authService.setTokens(this.authService.getIdToken(), data.accessToken);
                    if (uuid) {
                        search.set('uuid', uuid);
                    }// If is redirecting to LB Group page; Params for redirecting after they get stripped from coldfusion login

                    if (GroupUID && isDefault && ia) {
                        search.set('GroupUID', GroupUID);
                        search.set('SsDefault', isDefault);
                        search.set('ia', 0);
                    }

                    if (duid) {
                        search.set('duid', duid);
                    }
                    window.history.pushState({}, '', getPathName(path, search));
                }
            }, (error) => {
                console.log(error);
            }, headers);
        } else if (contextToken) {
            if (uuid) {
                search.set('uuid', uuid);
            }
            if (duid) {
                search.set('duid', duid);
            }

            window.history.pushState({}, '', getPathName(path, search));
        }

        if (uuid) {
            this.authService.setUUID(uuid);

            // It is fixed problem when admin user select one user
            // and then goes to edit another user under that account
            if (!getSelectedUUID()) {
                setSelectedUUID(uuid);
            }
        }
    }

    /**
     * Clear timeout for auto logout and close the modal.
     */
    closeModal() {
        storeAutoLogoutInfo({ isAutoLogoutCancelled: true, isAdmin: this.state.isAdmin });
        clearTimeout(this.autologoutConfirmationTimer);
        this.authService.refresh();
        this.setState({ modalIsOpen: false });
    }

    /**
     * Clears the timeout for auto logout
     * Clears local storage and redirects user to login page.
     */
    logout() {
        const { modalIsOpen, isAdmin } = this.state;

        if (modalIsOpen) {
            storeAutoLogoutInfo({ isAutoLogoutCancelled: false, isAdmin });
            clearTimeout(this.autologoutConfirmationTimer);

            this.authService.clear();
            window.location.href = `${isAdmin ? '/admin' : ''}/logout`;
        }
    }

    /**
     * Initiates new timer and opens modal. If autologout modal wait time exceeded, and modal still opened,
     * performs logout and clears timer
     */
    showPopUp() {
        removeAutoLogoutInfo();
        this.autologoutConfirmationTimer = setTimeout(this.logout, Config.autologoutModalWaitTime);

        this.setState({ modalIsOpen: true });
    }

    /**
     * Separated Modal-preparing method. No need to call this until user is not logged in
     * @param {boolean} modalIsOpen
     * @return {ConfirmPopupComponent} Modal message for autologout message
     */
    prepareModal(modalIsOpen) {
        const {
            User_EmailAddress: userEmail,
            name: userName
        } = this.authService.getProfile();
        const { t } = this.props;

        const { isMasquerade } = this.state;
        const messageText = isMasquerade ? 'auto-logout-modal__content--masquerade': 'auto-logout-modal__content';
        const messageButtonText = isMasquerade ? 'auto-logout-modal__button--masquerade' : 'auto-logout-modal__button';

        return (
            <ConfirmPopupComponent
                dialogClassName="hme-auto-logout-modal"
                title={'auto-logout-modal__title'}
                show={modalIsOpen}
                message={t(messageText, { userName: userEmail || userName })}
                actions={[{
                    children: t(messageButtonText),
                    variants: ['submit'],
                    onClick: this.closeModal,
                }]}
                onHide={this.logout}
            />
        );
    }

    /**
     * @return {JSX} template consisting headers, navbars, passed component and footer.
     */
    render() {
        const {
            children,
            Params: { contentClasses = [] } = {},
            location: {
                pathname: paramsPath = ''
            } = {},
        } = this.props;

        const { isAdmin, isPartner, isMasquerade } = this.state;
        const isOnDeviceUpgradesAdminSubmenu = (paramsPath.includes('admin') &&
            paramsPath.includes('settings') && (paramsPath.includes('stores') || paramsPath.includes('deviceUpgrades')) );
        const isLoggedIn = this.authService.isLoggedIn();
        const userProfile = this.authService.getProfile();
        const isGamified = userProfile && userProfile.IsNitro && userProfile.SL === 6;
        const showUpdatedTOSModal = !isAdmin && isLoggedIn && !isDFARequired() && !isMasquerade && userProfile.Account_TOS_Agree===1 ;
        const { modalIsOpen } = this.state;
        const { pathname } = window.location;

        const isLeaderBoardsPage = paramsPath.includes('leaderBoards');

        const { divClassName = '' } = getRouteClassNamesList(isAdmin, isGamified).find((cl) => pathname.includes(cl.path));

        const onIdle = () => {
            // wait for the user to be idle for 1 second before showing the popup
            // this is to prevent the popup to trigger focus event that resets the idle timer in other tabs
            setTimeout(() => {
                this.showPopUp();

                if (!this.authService.isLoggedIn()) {
                    this.authService.clear();
                    window.location.href = this.authService.getAppUrl();
                }
            }, AUTO_LOGOUT_SYNC_TIMERS_MS);
        };

        return (
            <div className="hme-page">
                {isLoggedIn && (
                    <IdleTimer
                        onIdle={onIdle}
                        idleTimeout={Config.idleTimeBeforeAutologout}
                        crossTab
                        syncTimers={AUTO_LOGOUT_SYNC_TIMERS_MS}
                    />
                )}
                {isLoggedIn && this.prepareModal(modalIsOpen)}
                { showUpdatedTOSModal && <UpdatedTOSModal/> }
                <HmeHeader />
                <AdminSubHeader />
                <DeviceSettingsSubHeader />
                {isAdmin && <ToolsSubHeader />}
                {isAdmin && <AdminReportsSubHeader />}
                <div>
                    <SettingsHeader isPartner={isPartner} isAdmin={isAdmin} profile={userProfile} />
                </div>
                <DeviceUpgradesSubHeader isAdmin={isAdmin && isOnDeviceUpgradesAdminSubmenu}/>
                { isLeaderBoardsPage && <LeaderBoardsHeader /> }
                <div className={classNames('hme-page-content', divClassName, ...contentClasses)}>
                    {children}
                </div>
                <Footer/>
            </div>
        );
    }
}

Layout.propTypes = {
    navigate: PropTypes.func,
    location: PropTypes.object,
    queryParams: PropTypes.object
};

export default compose(
        withTranslation(),
        withReactRouter
)(Layout);

import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InputComponent as Input } from 'components/Inputs';
import { Label } from 'library/Label';
import { Button } from 'library/Button';

import { ReactComponent as WarningIcon } from 'assets/icons/alert.svg';

import './Form.scss';

export const Form = ({ attempts, maxAttempts, isExceeded, onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const loginButtonDisabled = useMemo(() => username.trim().length < 3 || password.length < 8, [username, password]);
    const { t } = useTranslation();

    const onFormSubmit = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        if (loginButtonDisabled) {
            return false;
        }

        onLogin && onLogin({
            username,
            password,
        });
    }, [username, password, loginButtonDisabled, onLogin]);

    return (
        <form className='hme-login-form' onSubmit={onFormSubmit}>
            {
                attempts !== 0 &&
                    <Label className='hme-login-form__attempts'>
                        <WarningIcon className='hme-login-form__attempts__icon'/>
                        <span className='hme-login-form__attempts_text'>{t('login__attempts', { attempts, maxAttempts })}</span>
                    </Label>
            }
            {
                isExceeded &&
                    <Label className='hme-login-form__attempts-exceeded'>{t('login__attempts__exceeded')}</Label>
            }
            {
                !isExceeded &&
                    <>
                        <Input
                            variants={['label-inline']}
                            label={t('common__user__username')}
                            name='username'
                            maxLength={100}
                            value={username}
                            onChange={setUsername}
                        />
                        <Input
                            variants={['label-inline']}
                            label={t('common__user-password')}
                            name='password'
                            maxLength={64}
                            type='password'
                            value={password}
                            onChange={setPassword}
                        />
                        <Button
                            className='hme-login-form__login-button'
                            variants={['submit']}
                            disabled={loginButtonDisabled}
                            type='submit'
                        >
                            {t('login__login-button')}
                        </Button>
                    </>
            }
        </form>
    );
};

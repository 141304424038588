import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { useEffect } from 'react';
import Api from 'Api';
import AuthenticationService from 'components/Security/AuthenticationService';
import { baseAuthService } from 'components/Security/AuthenticationService';

/**
 *  Hook that handles stores and permission checking before executing the callback
 *  @param {callback} callback function to execute after stores and permissions are checked
 *  @param {function} setShowCommonLoader
 *  @param {function} setDetailsChecked
 *  @param {object} detailsChecked
 *  @param {object} dependencies array or variable with the name of the dependencies
 */
export const useCheckAccount = (callback, setShowCommonLoader, setDetailsChecked, detailsChecked, dependencies) => {
    const authService = new AuthenticationService(Config.authBaseUrl);
    const api = new Api();
    useEffect(() => {
        // Calls API to check user permissions
        // After successfull callback calls next function
        const getUserPermissions = () => new Promise((resolve, reject) => {
            const profile = baseAuthService.getProfile();
            if (profile) {
                const { Subscription_ID: subscription_ID } = profile || {};
                if (subscription_ID === 3 || subscription_ID === 8) {
                    // Basic Account
                    setDetailsChecked({
                        ...detailsChecked,
                        noPermissions: true,
                        accountError: true
                    });
                    setShowCommonLoader(false);
                    reject();
                } else {
                    setDetailsChecked({
                        ...detailsChecked,
                        noPermissions: false
                    });
                    resolve();
                }
            } else {
                setDetailsChecked({
                    ...detailsChecked,
                    accountError: true,
                    noPermissions: true
                });
                setShowCommonLoader(false);
                reject();
            }
        });

        // Calls API to check account stores
        // After successfull callback calls next function
        const getUserStores = () => new Promise((resolve, reject) => {
            const uuid = authService.isAdmin() ? authService.getUUID() : authService.getLoggedInUserUID();

            const url =
                Config.apiBaseUrl +
                CommonConstants.apiUrls.getStores +
                '?uuid=' + uuid;

            api.getData(url, (data) => {
                if (data.status) {
                    if (data.storeList.length === 0) {
                        // No stores
                        setDetailsChecked({
                            ...detailsChecked,
                            noStores: true,
                            accountError: true
                        });
                        setShowCommonLoader(false);
                        reject();
                    } else {
                        setDetailsChecked({
                            ...detailsChecked,
                            noStores: false,
                            accountError: false
                        });
                        resolve(data.storeList);
                    }
                } else {
                    // Status is false
                    setDetailsChecked({
                        ...detailsChecked,
                        noStores: true,
                        accountError: true
                    });
                    setShowCommonLoader(false);
                    reject();
                }
            });
        });

        // First calls getUserPermissions to check for account status
        // After confirming the account has permissions calls getUserStores
        // Checks for stores registered in the account
        // After confirming stores, execute the callback function
        const activate = () => {
            getUserPermissions()
                    .then(getUserStores, () => console.log('No permissions'))
                    .then(callback, () => console.log('No stores'));
        };

        activate();
        return () => {
            // clean up
            setDetailsChecked({});
            setShowCommonLoader(true);
        };
    }, [dependencies]);
};

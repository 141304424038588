import { reportNames } from 'components/LandingPage/ReportLandingPage/reportConstant';
import { COMMON_ROUTES } from 'constants/routes';

export const MenuItems = [
    {
        title: 'reports__multiStore',
        path: COMMON_ROUTES.multi
    },
    {
        title: 'reports__singleStore',
        path: COMMON_ROUTES.single
    },
    {
        title: 'reports__trends-dashboard',
        path: COMMON_ROUTES.trendsHistory
    },
    {
        title: 'reports__trends',
        path: COMMON_ROUTES.trends
    },
    {
        title: 'admin__hme-reports__sub-header--outliers',
        path: COMMON_ROUTES['outliers-client']
    },
    {
        title: 'reports__title-performance-analysis',
        path: COMMON_ROUTES['performance-analysis']
    },
    {
        title: reportNames['self-service'],
        path: COMMON_ROUTES['self-service']
    },
    {
        title: 'reports__rawData',
        path: COMMON_ROUTES.rcd
    },
    {
        title: 'reports__scheduler',
        path: COMMON_ROUTES.scheduledReports
    },
    {
        title: 'reports__docs',
        path: 'reports__docs__url'
    }
];

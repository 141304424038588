import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en-US',
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'userLanguage',
        },
        load: 'currentOnly',
        ns: ['translations'],
        defaultNS: 'translations',

        debug: false,

        react: {
            wait: true,
            useSuspense: true,
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          }
    });

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, applySearchValue } from 'components/Common/Grid';
import { Paginate } from 'library/Paginate';
import { PAGE_DATA } from 'constants/paginationDefault';
import { getProfile, isAdmin } from 'services/Auth';
import { useQuery } from 'hooks/useQuery';
import { searchOptions } from '../searchOptions';
import { sortBySerialNumber, sortByVersion, sortByStatus, sortByStoreProps, sortByProductID } from '../helpers';
import { StoreSerialNumbers } from '../../../StatusPage/Common/List/StoreSerialNumbers';
import { DeviceProductIDs } from './DeviceProductIDs';
import { StoreVersions } from './StoreVersions';
import { StoreStatuses } from './StoreStatuses';
import { ViewButton } from './ViewButton';
import { TilesList } from './TilesList';
import { headers} from './headers';

import './List.scss';

const storeToRow = ({ store, isCorpUser, profile, uuid, isAdminUser }) => ({
    ...store,
    brandText: isCorpUser && profile.User_Company_ID === store.Store_Company_ID ?
        `${store.Brand_Name}*` :
        store.Brand_Name,
    cityString: store.Store_Locality && store.Store_Region ?
        `${store.Store_Locality}, ${store.Store_Region}` :
        store.Store_Locality || store.Store_Region || '',
    Actions: (<ViewButton suid={store.Store_UID} uuid={uuid} isAdminUser={isAdminUser} />),
    SerialNumber: (<StoreSerialNumbers devices={store.Device_Details} />),
    DeviceProductID: (<DeviceProductIDs devices={store.Device_Details} />),
    Version: (<StoreVersions devices={store.Device_Details} />),
    Status: (<StoreStatuses devices={store.Device_Details} />),
    DeviceSerialNumbers: store.Device_Details
        .map(({ Device_SerialNumber }) => Device_SerialNumber)
        .filter(serialNumber => !!serialNumber)
        .join(' '),
    DeviceVersions: store.Device_Details
        .map(({ Device_MainVersion }) => Device_MainVersion)
        .filter(veriosn => !!veriosn)
        .join(' '),
    DeviceProductIDs: store.Device_Details
        .map(({ Device_Product_ID }) => Device_Product_ID)
        .filter(DeviceProductID => !!DeviceProductID)
        .join(' '),
});

const allSearchFields = searchOptions
    .map(({ value }) => value)
    .filter(value => !!value);

const pageSizes = [10, 20, 50, -1];

const sortDirections = [1, -1];

const sortRows = (rows, sorting) => {
    const prop = Object.keys(sorting)[0];
    const direction = sorting[prop];

    switch (prop) {
        case 'SerialNumber':
            return sortBySerialNumber(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'DeviceProductID':
            return sortByProductID(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'Version':
            return sortByVersion(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'Status':
            return sortByStatus(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'brandText':
            return sortByStoreProps(rows, direction, 'Brand_Name');

        default:
            return sortByStoreProps(rows, direction, prop);
    }
};

const filterAndSort = (searchValue, rows, sorting, setFilteredRows, setPage, setIsFiltering) => {
    setIsFiltering(true);

    setTimeout(() => {
        const fields = searchValue.selectValue ? [searchValue.selectValue] : allSearchFields;
        const newFilteredRows = applySearchValue(rows, fields, searchValue.searchValue, { minLength: 1 });
        const sortedRows = sortRows(newFilteredRows, sorting);

        setFilteredRows([...sortedRows]);
        setIsFiltering(false);
    }, 50);
};

export const List = ({
    stores,
    searchValue,
    sorting,
    isCorpUser = false,
    isLoading,
    onRenderingChange,
    onSorting,
    isAdminUser,
}) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [pagedRows, setPagedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const profile = useMemo(() => getProfile(), []);

    const { t } = useTranslation();
    const query = useQuery();
    const uuid = useMemo(() => query.get('uuid') || null, [query]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, setPage]);

    useEffect(() => {
        const newRows = stores.map((store) => storeToRow({ store, isCorpUser, profile, isAdminUser, uuid }));

        setRows(newRows);
    }, [stores, isCorpUser, profile, isAdminUser, uuid, setRows]);

    useEffect(() => {
        filterAndSort(searchValue, rows, sorting, setFilteredRows, setPage, onRenderingChange);
    }, [rows, searchValue, sorting, setFilteredRows, setPage, onRenderingChange]);

    useEffect(() => {
        if (rowsPerPage === -1) {
            return setPagedRows(filteredRows);
        }

        const newRows = filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        setPagedRows(newRows);
    }, [filteredRows, page, rowsPerPage, setPagedRows]);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPage(page);
        setRowsPerPage(recordsPerPage === -1 ? recordsPerPage : Number.parseInt(recordsPerPage));
    }, [setPage, setRowsPerPage]);

    const onSortingChange = useCallback((newSorting) => {
        if (isLoading) {
            return;
        }

        onSorting(newSorting);
    }, [isLoading, onSorting]);

    return (
        <>
            <Grid
                headers={headers}
                rows={pagedRows}
                rowKey='Store_UID'
                className='hme-stores-status--public__grid hme-l-size'
                noRecordsMessage={t('common__no-stores--found')}
                isLoading={isLoading}
                loadingText={t('common__loading')}
                onSortChange={onSortingChange}
                sortSelection={sorting}
                sortDirections={sortDirections}
            />
            <TilesList
                stores={pagedRows}
                isLoading={isLoading}
                uuid={uuid}
                isAdminUser={isAdminUser}
            />
            { !isLoading &&
                    <Paginate
                        className='hme-stores-status--public__paginate'
                        page={page}
                        recordsPerPage={rowsPerPage}
                        pageSizes={ profile.admin ? PAGE_DATA.PAGE_SIZES_ADMIN : PAGE_DATA.PAGE_SIZES_PUBLIC}
                        total={filteredRows.length}
                        onChange={onPaginationChange}
                    />
            }
        </>
    );
};

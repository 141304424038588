import { Config } from '../../Config';
import AuthenticationService from '../Security/AuthenticationService';

const settingTabs = ['applyDeviceSettingsStatus', 'applyDeviceSettings', 'manageDeviceSettings', 'applyStoreSettingsStatus', 'mergeDevices'];
const toolsTabs = ['manageBlockedEmails', 'dxsFeeds', 'vaioProviders', 'createVaioProvider', 'editVaioProvider', 'firmwareManagement'];

export default class SettingService {
    constructor() {
        this.authService = new AuthenticationService(Config.authBaseUrl);
    }

    canShowAdminHeader() {
        return this.authService.isAdmin() && this.authService.isLoggedIn();
    }

    canShowAdminSubHeader(uuid) {
        const page = this.authService.getUrlMenu();
        const isSettings = settingTabs.includes(page);
        const isTools = toolsTabs.includes(page);

        if (uuid && !isSettings && !isTools) {
            return this.authService.isAdmin() && this.authService.isLoggedIn();
        } else {
            return false;
        }
    }

    canShowPortalHeader() {
        return !this.authService.isAdmin() && this.authService.isLoggedIn();
    }
}

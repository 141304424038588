import 'core-js/es/set';
import 'core-js/es/map';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

import { Analytics } from 'library/Analytics';
import { GlobalStateProvider } from 'globalState';
import { LoadGlobalLocale } from 'library/LoadGlobalLocale';
import { CacheManager } from 'library/CacheManager';
import { PageUrlManager } from 'library/PageUrlManager';
import { CenterLoader } from 'components/Common/CenterLoader';
import { isPartner } from 'services/Auth';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import reducers from 'reducers/store';

import './index.css';
import './App.css';
import './hme-components.scss';
import './hme-translation-loader.scss';

import { defaultAppRoutes, partnerRoutes } from 'apps';

const store = createStore(reducers, composeWithDevTools(
    applyMiddleware(thunkMiddleware)
    // other store enhancers if any
));
const app = document.getElementById('root');

const root = createRoot(app);

if (!window.location.pathname.includes('/settings/stores')) {
    window.localStorage.removeItem('storeListShowCommonLoader');
}

const ProviderApp = ({ children }) => {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <Suspense fallback={<CenterLoader variants={['root-loader']} />}>
                    <GlobalStateProvider>
                        <BrowserRouter>
                            <div>
                                <Analytics />
                                <PageUrlManager />
                                <LoadGlobalLocale />
                                <CacheManager />

                                {children}
                            </div>
                        </BrowserRouter>
                    </GlobalStateProvider>
                </Suspense>
            </Provider>
        </ErrorBoundary>
    );
};

const getApp = () => {
    if (isPartner()) {
        return partnerRoutes();
    }

    return defaultAppRoutes();
};

root.render(<ProviderApp>{getApp()}</ProviderApp>);
